<script lang="ts" setup>
import { onMounted, ref } from "vue";
import { Role, InvitedUserInterface } from "../../../interface/UserInterface";
import DeleteUserModal from "./DeleteUserModal.vue";
import EditUserModal from "./EditUserModal.vue";
import router from "../../../routes";
import UserInvitationModal from "./UserInvitationModal.vue";
import AdminCertificationModal from "./AdminCertificationModal.vue";
import ProfileIconComponent from "../../../components/ProfileIconComponent.vue";
import { appContainer } from "../../../container";
import { ApiCallService } from "../../../services/apiCall.service";
import { StorageKeys, StorageService } from "../../../services/storage.service";
import { TokenInterface } from "../../../interface/TokenInterface";
import { ReportService } from "../../../services/reportService";
import { ActiveRolesStore } from "../../../stores/ActiveRolesStore";
import { ToastService } from "../../../services/toastService";
import { LoadingService } from "../../../services/loadingService";
import { UserService } from "../../../services/userService";
import { getDaysLeft } from "../../../utils/getDaysleft";
import CompletionFeedbackModal from "./CompletionFeedbackModal.vue";
import { useUserStore } from "../../../stores/UserStore";

const props = defineProps({
  user: {
    type: Object as () => InvitedUserInterface,
    required: true,
  },
  showDetail: {
    type: Boolean,
    default: true,
  },
  forReports: {
    type: Boolean,
    default: false,
  },
  companyId: {
    type: Number,
  },
  isCertified: {
    type: Boolean,
  },
});

const emit = defineEmits(["invite", "delete", "edit", "certified", "done"]);

const showDeleteModal = ref(false);
const toBeDeleted = ref();
const currentModalUser = ref();
const showEditModal = ref(false);
const showInviteUserModal = ref(false);
const token = ref("");
const pdfUrl = ref("");
const activeRoleStore = ActiveRolesStore();
const availableReports = ref(0);
const showCertificationModal = ref(false);
const showFeedbackCompletionModal = ref(false);
const isReportSent = ref(false);

const apiCallService = appContainer.resolve(ApiCallService);
const storageService = appContainer.resolve(StorageService);
const reportService = appContainer.resolve(ReportService);
const toastService = appContainer.resolve(ToastService);
const loadingService = appContainer.resolve(LoadingService);
const userService = appContainer.resolve(UserService);
const daysLeft = ref(0);
const userStore = useUserStore();

onMounted(async () => {
  if (props.forReports && props.user.invitation_type === Role.Participants) {
    await getPdfUrl();
    daysLeft.value = getDaysLeft(props.user);
    isReportSent.value = props.user.meta.report_sent;
  }

  if (props.forReports && props.user.invitation_type === Role.Admin) {
    await getReportsCount();
  }
});

function emitInvite(id: number) {
  closeInviteModal();
  emit(
    "invite",
    id,
    !props.user.invitation_sent ? props.user.invitation_type : undefined,
  );
}

function openDeleteModal(id: number) {
  toBeDeleted.value = id;
  showDeleteModal.value = true;
}

function openEditModal(user: InvitedUserInterface) {
  currentModalUser.value = user;
  showEditModal.value = true;
}

function emitEdit() {
  closeEditModal();
  emit("edit");
}

function closeEditModal() {
  showEditModal.value = false;
}

function showDetails(userID: number) {
  if (props.forReports && props.user.invitation_type === Role.Admin) {
    router.push(`/manage-reports/${userID}/participant`);
  }
  if (!props.showDetail) {
    return;
  }
  router.push(`/manage-users/user-details/${userID}`);
}

function emitDelete(id: number) {
  showDeleteModal.value = false;
  emit("delete", id, props.user.invitation_type);
}

function closeInviteModal() {
  showInviteUserModal.value = false;
}

function closeCertificationModal() {
  showCertificationModal.value = false;
}

function handleCertify() {
  closeCertificationModal();
  emit("certified");
}

function openCertificationModal() {
  showCertificationModal.value = true;
}

function openFeedbackCompletionModal() {
  showFeedbackCompletionModal.value = true;
}
function closeFeedbackCompletionModal() {
  showFeedbackCompletionModal.value = false;
}

function handleFeedbackCompletion() {
  closeFeedbackCompletionModal();
  emit("done");
}

async function getPdfUrl() {
  token.value = (
    (await storageService.get(StorageKeys.TOKEN)) as TokenInterface
  ).access_token;
  if (activeRoleStore.activeRoles.includes("super_admin")) {
    pdfUrl.value = `${apiCallService.baseUrl()}/invitations/${props.user.id}/report/pdf?token=${token.value}`;
  } else {
    pdfUrl.value = `${apiCallService.baseUrl()}/invitations/${props.user.id}/report/pdf?token=${token.value}&loginInvitationId=${userStore.admin?.id}`;
  }
}
// function getDate() {
//   const sentOn = new Date(props.user.sent_on);
//   sentOn.setDate(sentOn.getDate() + Number(props.user.meta.duration_days));
//   const day = sentOn.getDate();
//   const month = sentOn.toLocaleString("default", { month: "long" });
//   const year = sentOn.getFullYear();

//   return `${day} ${month} ${year}`;
// }

async function sendPdf() {
  const loader = await loadingService.show();
  const loginInvitationId = activeRoleStore.activeRoles.includes("super_admin")
    ? null
    : userStore.admin?.id;
  await reportService
    .sendReportPdf(props.user.id, loginInvitationId)
    .then(() => {
      toastService.success("PDF Sent Successfully");
      isReportSent.value = true;
    })
    .catch(() => toastService.error("Unable to Send PDF"))
    .finally(() => loader.hide());
}

const formatDate = (dateString: Date | string) => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  return date.toLocaleDateString("en-US", options);
};

async function getReportsCount() {
  await userService
    .getInvitedUser({
      invitationType: Role.Participants,
      invited_for: props.user.invited_user.id,
      all: true,
      invitation_sent: true,
    })
    .then(
      (result) =>
        (availableReports.value = result.items.filter(
          (item) => getDaysLeft(item) < 0,
        ).length),
    );
}
</script>

<template>
  <div
    class="user-card d-flex border-bottom p-md-3 py-2 align-items-center"
    :class="{
      'cursor-pointer': !props.companyId,
      'no-pointer':
        (forReports && user.invitation_type === Role.Participants) ||
        !showDetail,
    }"
    @click.stop="showDetails(props.user.id)"
  >
    <ProfileIconComponent :user="props.user" />
    <div class="d-flex flex-column justify-content-center">
      <span
        class="fw-semibold name text-capitalize d-flex align-items-center gap-2"
        >{{ props.user.invited_user.first_name }}
        {{ props.user.invited_user.last_name }}
        <span
          v-if="
            props.user.invited_user.certification &&
            router.currentRoute.value.fullPath === '/admins'
          "
          class="badge rounded-pill text-bg-secondary text-white"
          >Certified</span
        ></span
      >
      <span class="email break-word">{{ props.user.invited_user.email }}</span>
      <div
        class="role-wrapper"
        v-if="props.forReports && props.user.invitation_type === Role.Admin"
      >
        <span>
          Available Reports: {{ availableReports }}/{{ props.user.item_count }}
        </span>
      </div>
      <div
        class="role-wrapper"
        v-if="
          props.forReports && props.user.invitation_type === Role.Participants
        "
      >
        <span>
          Assessment Completion Date:
          {{ formatDate(props.user.meta.feedback_completion_date) }}</span
        >
      </div>

      <div
        class="role-wrapper d-flex align-items-center mt-1"
        v-if="!props.forReports"
      >
        <span
          class="invite-tag"
          :class="props.user.invitation_sent ? 'text-secondary' : 'text-danger'"
          >{{ props.user.invitation_sent ? "Invited" : "Not Invited" }}</span
        >
        <span class="pipe">|</span>
        <span class="text-capitalize"> {{ props.user.invitation_type }}</span>
        <span
          v-if="
            props.user.invitation_type === Role.Participants ||
            props.user.invitation_type === Role.Admin
          "
          class="pipe"
          >|</span
        >
        <span v-if="props.user.invitation_type === Role.Participants"
          >Invited Raters: {{ user.item_count }}
        </span>
        <span v-if="props.user.invitation_type === Role.Admin"
          >Invited Participants: {{ user.item_count }}
        </span>
        <span
          v-if="
            user.company?.name &&
            props.user.invitation_type === Role.Admin &&
            router.currentRoute.value.fullPath === '/admins'
          "
          class="pipe"
          >|</span
        >
        <span
          v-if="
            user.company?.name &&
            props.user.invitation_type === Role.Admin &&
            router.currentRoute.value.fullPath === '/admins'
          "
          >Company Name: {{ user.company?.name }}
        </span>
        <span
          class="pipe"
          v-if="
            props.user.invitation_type === Role.Participants ||
            props.user.invitation_type === Role.Raters
          "
        >
          |
        </span>
        <span
          v-if="props.user.invitation_type === Role.Participants"
          class="break-word"
        >
          Admin: {{ props.user.invited_for_user?.email }}
        </span>
        <span
          class="pipe"
          v-if="
            props.user.invitation_type === Role.Participants &&
            props.user.invitation_sent
          "
        >
          |
        </span>
        <span
          v-if="
            props.user.invitation_type === Role.Participants &&
            props.user.invitation_sent
          "
        >
          Days Left: {{ user.days_left }}
        </span>
        <span
          v-if="props.user.invitation_type === Role.Raters"
          class="break-word"
          >Participant: {{ props.user.invited_for_user?.email }}
        </span>
        <!-- <span class="pipe"> | </span> -->
        <!-- <span> Created on: {{ formatDate(user.created_at) }} </span> -->
      </div>
    </div>
    <div
      v-if="!props.forReports"
      class="d-flex align-items-center action-items ms-auto"
    >
      <button v-if="showDetail" title="View More" class="btn border-0 p-1">
        <i class="bi bi-arrow-up-right-square"></i>
      </button>
      <button
        title="Send Invitation Email"
        class="btn border-0 p-1"
        @click.stop="showInviteUserModal = true"
      >
        <i class="bi bi-envelope"></i>
      </button>
      <button
        v-if="
          !user.invitation_sent ||
          (user.invitation_sent &&
            user.invitation_type === Role.Participants &&
            !user.meta.feedback_done &&
            user.days_left! > 0)
        "
        title="Edit User"
        class="btn border-0 p-1"
        @click.stop="openEditModal(props.user)"
      >
        <i class="bi bi-pencil-square"></i>
      </button>
      <button
        v-if="!user.invitation_sent"
        title="Delete Invitation"
        class="btn border-0 p-1"
        @click.stop="openDeleteModal(props.user.id)"
      >
        <i class="bi bi-trash3"></i>
      </button>
    </div>
    <div v-if="isCertified">
      <button @click="openCertificationModal" class="btn border-0 fs-5 p-1">
        <i class="bi bi-patch-check"></i>
      </button>
    </div>
    <div
      class="d-flex align-items-center action-items ms-auto"
      v-if="
        props.forReports && props.user.invitation_type === Role.Participants
      "
    >
      <template v-if="!props.user.meta.feedback_done && daysLeft >= 0">
        <button
          v-if="user.invitation_sent"
          class="btn border-0 p-1"
          title="Feedback Done"
          @click="openFeedbackCompletionModal"
        >
          <i class="bi bi-check2-all"></i>
        </button>
      </template>
      <template v-else-if="props.user.meta.feedback_done || daysLeft < 0">
        <a target="_blank" :href="pdfUrl" class="btn">
          <i class="bi bi-download"></i>
        </a>
        <button class="btn border-0" @click="sendPdf">
          <i
            class="bi"
            :class="isReportSent ? 'bi-envelope-check-fill' : 'bi-envelope'"
          ></i>
        </button>
      </template>
    </div>
  </div>
  <DeleteUserModal
    v-if="showDeleteModal"
    :invitation="props.user"
    @close="showDeleteModal = false"
    @delete="emitDelete"
  />

  <EditUserModal
    v-if="showEditModal"
    :user="currentModalUser"
    @close="closeEditModal"
    @edit="emitEdit"
  />
  <UserInvitationModal
    v-if="showInviteUserModal"
    :invitation="props.user"
    @invite="emitInvite"
    @close="closeInviteModal"
  />

  <AdminCertificationModal
    v-if="showCertificationModal"
    :userId="props.user.invited_user.id"
    @certified="handleCertify"
    @close="closeCertificationModal"
  />

  <CompletionFeedbackModal
    v-if="showFeedbackCompletionModal"
    :invitation="props.user"
    @done="handleFeedbackCompletion"
    @close="closeFeedbackCompletionModal"
  />
</template>

<style lang="scss" scoped>
@import "../../../theme/variables";

.user-card {
  transition: all ease 300ms;
  gap: 12px;
}

.email {
  font-size: 12px;
  color: #6e6e6e;
}

.invite-tag {
  font-weight: 500;
  font-size: 11px !important;
}

.role-wrapper {
  gap: 10px;
  span {
    font-size: 12px;
  }
}

.action-items {
  gap: 10px;

  .btn {
    font-size: 18px;

    transition: ease 300ms;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.no-pointer {
  cursor: default;
}
@media (max-width: 575px) {
  .role-wrapper {
    flex-direction: column;
    align-items: start !important;
    gap: 0;
  }
  .pipe {
    display: none;
  }
  .action-items {
    justify-content: center;
    flex-direction: column;
    gap: 8;
    .btn {
      font-size: 16px;
      padding: 2px !important;
    }
  }
}
</style>
