import type { NavigationGuardWithThis } from "vue-router";
import { appContainer } from "../container";
import { UserService } from "../services/userService";
import { StorageKeys, StorageService } from "../services/storage.service";
import {
  InvitedUserInterface,
  RoleInterface,
} from "../interface/UserInterface";
import { AuthService } from "../services/authService";

export const isCurrentInvitationValid: NavigationGuardWithThis<
  undefined
> = async () => {
  const userService = appContainer.resolve(UserService);
  const storageService = appContainer.resolve(StorageService);
  const authService = appContainer.resolve(AuthService);
  const currentInvitation = await storageService.get<InvitedUserInterface>(
    StorageKeys.Current_Invitation,
  );
  const activeRole = await storageService.get<RoleInterface>(
    StorageKeys.Active_Roles,
  );
  async function handleLogout() {
    authService.logout();
  }

  if (activeRole?.role_name != "super_admin") {
    const validInvitation = await userService.getCurrentInvitation(
      currentInvitation!.id,
    );
    return validInvitation ? true : handleLogout();
  } else {
    return true;
  }
};
