<script setup lang="ts">
import { onMounted, ref, watch } from "vue";
import AddUserModal from "../manage-users/components/AddUserModal.vue";
import { appContainer } from "../../container";
import { UserService } from "../../services/userService";
import {
  InvitedUserInterface,
  Role,
  CertificationStatus,
} from "../../interface/UserInterface";
import { PagingInterface } from "../../interface/PaginationInterface";
import { LoadingService } from "../../services/loadingService";
import UserListingCard from "../manage-users/components/UserListingCard.vue";
import PaginationComponent from "../../components/PaginationComponent.vue";

const showAddModal = ref(false);
const isLoading = ref(true);
const userService = appContainer.resolve(UserService);
const loadingService = appContainer.resolve(LoadingService);
const pageConfig = ref<{ page?: number; offset?: number }>({
  page: 1,
  offset: 8,
});

const paginatedData = ref<PagingInterface<InvitedUserInterface>>();
const admins = ref<InvitedUserInterface[]>([]);
const searchField = ref("");

const certification = ref<CertificationStatus>(
  CertificationStatus.NonCertified,
);

onMounted(async () => {
  await getAdmins().then(() => (isLoading.value = false));
});

async function getAdmins() {
  const loader = await loadingService.show();
  await userService
    .getInvitedUser(
      {
        invitationType: Role.Admin,
        name: searchField.value,
        certification: certification.value,
        allUsers: true,
      },
      pageConfig.value,
    )
    .then((result) => {
      paginatedData.value = result;
      admins.value = result.items;
    })
    .finally(() => loader.hide());
}

function updatePage(pageNumber: number) {
  if (pageNumber === paginatedData.value?.page) {
    return;
  }
  pageConfig.value.page = pageNumber;
  getAdmins();
}

function searchInvitedUser() {
  if (searchField.value === "") {
    return;
  }
  pageConfig.value.page = 1;
  getAdmins();
}

watch(searchField, () => {
  if (searchField.value === "") {
    pageConfig.value.page = 1;
    getAdmins();
  }
});

watch(certification, () => {
  getAdmins();
});
</script>

<template>
  <template v-if="!isLoading">
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h2 class="fw-medium fs-4 p-0">Admins</h2>
      <button
        class="btn btn-primary btn-sm text-white"
        @click="showAddModal = true"
      >
        Add Admin
      </button>
    </div>
    <form
      class="d-flex w-100 justify-content-between flex-wrap mb-4"
      @submit.prevent="searchInvitedUser"
    >
      <div class="search-wrapper d-flex m-0 position-relative w-100 gap-4">
        <input
          type="search"
          class="form-control"
          placeholder="Search"
          v-model="searchField"
        /><button type="submit" class="text-white btn bg-primary flex-center">
          Search
        </button>
      </div>
    </form>
    <ul
      class="nav nav-pills mb-3 justify-content-between justify-content-sm-start"
    >
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          @click="certification = CertificationStatus.NonCertified"
          :class="{
            active: certification === CertificationStatus.NonCertified,
          }"
        >
          Non-Certified
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          @click="certification = CertificationStatus.Certified"
          :class="{
            active: certification === CertificationStatus.Certified,
          }"
        >
          Certified
        </button>
      </li>
    </ul>
    <div v-if="admins.length">
      <UserListingCard
        v-for="admin in admins"
        :key="admin.id"
        :user="admin"
        :showDetail="false"
        :isCertified="
          admin.invitation_sent && !admin.invited_user.certification
        "
        @invite="getAdmins()"
        @certified="getAdmins()"
        @edit="getAdmins()"
        @delete="getAdmins()"
      />
    </div>
    <div
      class="no-result d-flex align-items-center justify-content-center text-opacity-50 text-danger"
      v-else
    >
      No Records Found
    </div>
    <div class="pagination-wrapper" v-if="paginatedData?.totalItems">
      <PaginationComponent
        v-if="paginatedData.totalItems > 8"
        :totalRows="paginatedData!.totalItems"
        :currentPage="paginatedData?.page"
        :perPage="paginatedData?.offset"
        @pageChange="updatePage"
      />
    </div>

    <AddUserModal
      v-if="showAddModal"
      :forAdmin="true"
      @add="
        () => {
          showAddModal = false;
          getAdmins();
        }
      "
      @close="showAddModal = false"
    />
  </template>
</template>

<style scoped lang="scss">
.search-wrapper {
  max-width: 350px;
}

.nav-link {
  border-radius: 0;
  border-bottom: 3px solid transparent;
  &.active {
    background-color: transparent;
    color: var(--bs-primary);
    border-color: var(--bs-primary);
  }
}
@media (max-width: 575px) {
  .nav-link {
    font-size: 14px;
  }
}
</style>
