<script setup lang="ts">
import { onMounted, ref } from "vue";
import { appContainer } from "../../../container";
import { CompanyService } from "../../../services/manageCompanyService";
import { useRoute } from "vue-router";
import { CompanyInterface } from "../../../interface/CompanyInterface";
import { StorageService, StorageKeys } from "../../../services/storage.service";

const currentCompany = ref<CompanyInterface>();

const storageService = appContainer.resolve(StorageService);
const companyService = appContainer.resolve(CompanyService);
const token = ref("");
const route = useRoute();

onMounted(async () => {
  await getCurrentCompany();
  token.value = (
    (await storageService.get(StorageKeys.TOKEN)) as any
  ).access_token;
});

async function getCurrentCompany() {
  currentCompany.value = await companyService
    .fetchCompanies()
    .then((result) =>
      result.items.find((item) => item.id === Number(route.params.id)),
    );
}
</script>
<template>
  <div
    class="listing-company d-flex align-items-center rounded w-100 p-3 mb-4"
    v-if="currentCompany"
  >
    <div class="img-company rounded overflow-hidden">
      <img
        v-if="currentCompany.logo_file"
        :src="`${currentCompany.logo_file}?token=${token}`"
        alt="Company Logo"
        class="w-100 h-100 object-fit-contain"
      />
      <i v-else class="bi bi-buildings-fill"></i>
    </div>
    <div class="company-info px-4 mx-2">
      <h4 class="ff-montserrat fs-medium text-capitalize mb-2">
        {{ currentCompany.name }}
      </h4>
      <p>
        {{ currentCompany.representative_email }}
      </p>
    </div>
  </div>
  <div>
    <h5 class="mb-4">Company Message:</h5>
    <p>{{ currentCompany?.description }}</p>
  </div>
</template>
<style lang="scss" scoped>
@import "../../../theme/variables";
.listing-company {
  background-color: #eaf3fe;
}
.img-company {
  width: 100px;
  height: 100px;
  color: $blue;
}
</style>
