import { createRouter, createWebHistory } from "vue-router";
import { RouteRecordRaw } from "vue-router";
import InitiateLoginView from "../views/InitiateLoginView.vue";
import AuthLanding from "../views/AuthLanding.vue";
import DashboardView from "../views/DashboardView.vue";
import ManageQuestionsView from "../views/manage-questions/ManageQuestionsView.vue";
import { redirectIfAuthenticatedGuard } from "../gaurds/redirectIfAuthenticatedGuard";
import { isAuthenticatedGuard } from "../gaurds/IsAuthenticatedGuard";
import ProfileView from "../views/ProfileView.vue";
import UserListView from "../views/manage-users/UserListView.vue";
import UserDetailView from "../views/manage-users/UserDetailsView.vue";
import ManageCompaniesView from "../views/manage-companies/ManageCompaniesView.vue";
import ManageEmployeeView from "../views/manage-companies/manage-employee/ManageEmployeeView.vue";
import InviteEmployeeListingView from "../views/manage-companies/manage-employee/InvitedEmployeeListingView.vue";
import CompanyQuestions from "../views/manage-companies/CompanyQuestions.vue";
import CompanyReports from "../views/manage-companies/CompanyReports.vue";
import AppContainerView from "../views/AppContainerView.vue";
import ManageReportsView from "../views/manage-reports/ManageReportsView.vue";
import AppSettingView from "../views/AppSettingView.vue";
import { FrozenQuestionGaurd } from "../gaurds/FrozenQuestionGaurd";
import SwitchAccountView from "../views/SwitchAccountView.vue";
// import { HasMultipleRoles } from "../gaurds/HasMultipleRoles";
import ManageParticipantView from "../views/admin/manage-participants/ManageParticipantView.vue";
import ManageRaterView from "../views/participant/manage-rater/ManageRaterView.vue";
// import { ActiveRoleGaurd } from "../gaurds/ActiveRoleGaurd";
// import { IsRoleRater, IsNotRoleRater } from "../gaurds/IsRoleRater";
import RaterInstructions from "../views/rater/RaterInstructions.vue";
import SampleQuestionView from "../views/rater/SampleQuestionView.vue";
import FeedbackView from "../views/rater/FeedbackView.vue";
import PageNotFound from "../components/PageNotFound.vue";
import ThankYouPage from "../views/rater/ThankYouPage.vue";
import PreviewFeedback from "../components/PreviewFeedback.vue";
import FeedbackSubmitted from "../views/rater/FeedbackSubmittted.vue";
import { FeedbackSubmittedGaurd } from "../gaurds/FeedbackSubmittedGaurd";
import ReportsPage from "../views/manage-reports/ReportsPage.vue";
import EditReportView from "../views/manage-reports/EditReportView.vue";
import CompanyDescription from "../views/manage-companies/components/CompanyDescription.vue";
import ManageResourcesView from "../views/manage-resources/ManageResourcesView.vue";
// import { FeedbackExpiredGaurd } from "../gaurds/FeedbackExpiredGaurd";
import FeedbackExpired from "../views/rater/FeedbackExpired.vue";
import { isCurrentInvitationValid } from "../gaurds/isCurrentInvitationValid";
import { RoleGuard } from "../gaurds/RoleGaurd";
import MyAssessments from "../views/assessments/MyAssessments.vue";
import AdminListingView from "../views/admin/AdminListingView.vue";
import { initGlobalState } from "../gaurds/initGlobalState";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: "InitiateLoginFlow",
    component: InitiateLoginView,
    beforeEnter: [redirectIfAuthenticatedGuard],
  },
  {
    path: "/auth-landing",
    name: "AuthLanding",
    component: AuthLanding,
    beforeEnter: [redirectIfAuthenticatedGuard],
  },
  {
    path: "/choose-roles",
    name: "ChooseAccountView",
    beforeEnter: [isAuthenticatedGuard],
    component: SwitchAccountView,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "PageNotFound",
    component: PageNotFound,
    beforeEnter: [isAuthenticatedGuard],
  },

  {
    path: "/thank-you",
    name: "ThankYouPage",
    component: ThankYouPage,
    beforeEnter: [isAuthenticatedGuard],
  },
  {
    path: "/feedback-submitted",
    name: "FeedbackSubmittted",
    component: FeedbackSubmitted,
    beforeEnter: [
      isAuthenticatedGuard,
      // IsNotRoleRater,
      isCurrentInvitationValid,
    ],
  },
  {
    path: "/feedback-expired",
    name: "FeedbackExpired",
    component: FeedbackExpired,
    beforeEnter: [isAuthenticatedGuard, isCurrentInvitationValid],
  },

  {
    path: "/",
    component: AppContainerView,
    beforeEnter: [
      isAuthenticatedGuard,
      initGlobalState,

      // HasMultipleRoles,
      // FeedbackExpiredGaurd,
      // isCurrentInvitationValid,
    ],
    redirect: "/dashboard",

    children: [
      {
        path: "dashboard",
        name: "DashboardView",
        beforeEnter: [
          RoleGuard(["super_admin", "admin", "participant", "rater"]),
        ],
        component: DashboardView,
      },
      {
        path: "questions",
        name: "ManageQuestionsView",
        component: ManageQuestionsView,
        beforeEnter: [RoleGuard(["super_admin"])],
      },
      {
        path: "companies",
        name: "ManageCompaniesView",
        component: ManageCompaniesView,
        beforeEnter: [RoleGuard(["super_admin", "admin", "participant"])],
      },
      {
        path: "profile",
        name: "ProfileView",
        component: ProfileView,
      },
      {
        path: "users",
        name: "UserListView",
        component: UserListView,
        beforeEnter: [FrozenQuestionGaurd],
      },
      {
        path: "users/user-details/:id",
        name: "UserDetailView",
        component: UserDetailView,
        beforeEnter: [FrozenQuestionGaurd],
      },

      {
        path: "company/:id",
        name: "ManageEmplyoeeView",
        component: ManageEmployeeView,
        beforeEnter: [FrozenQuestionGaurd],
        children: [
          {
            path: "manage-employees",
            name: "InviteEmployeeListingView",
            component: InviteEmployeeListingView,
          },
          {
            path: "reports",
            name: "CompanyReports",
            component: CompanyReports,
          },
          {
            path: "questions",
            name: "CompanyQuestions",
            component: CompanyQuestions,
          },
          {
            path: "description",
            name: "CompanyDescription",
            component: CompanyDescription,
          },
          {
            path: "reports/:participantId/edit",
            name: "CompanyEditReportView",
            component: EditReportView,
          },
        ],
      },
      {
        path: "manage-reports",
        beforeEnter: [isCurrentInvitationValid],
        children: [
          {
            path: "",
            name: "ManageReportsView",
            component: ManageReportsView,
          },
          {
            path: ":participantId/participant",
            name: "ReportsPage",
            component: ReportsPage,
          },
          {
            path: ":participantId/edit",
            name: "EditReportView",
            component: EditReportView,
          },
        ],
      },
      {
        path: "preview-feedback",
        name: "PreviewFeedback",
        component: PreviewFeedback,
        beforeEnter: [RoleGuard(["super_admin"])],
      },
      {
        path: "app-settings",
        name: "AppSettingView",
        component: AppSettingView,
      },
      {
        path: "manage-participants",
        name: "ManageParticipantView",
        component: ManageParticipantView,
        // beforeEnter: [isCurrentInvitationValid],
      },
      {
        path: "participant/:id",
        name: "ParticipantDetailView",
        component: UserDetailView,
        // beforeEnter: [isCurrentInvitationValid],
      },
      {
        path: "manage-raters",
        name: "ManageRaterView",
        component: ManageRaterView,
        beforeEnter: [],
      },
      {
        path: "rater/:id",
        name: "RaterDetailView",
        component: UserDetailView,
        // beforeEnter: [isCurrentInvitationValid],
      },
      {
        path: "/switch-roles",
        name: "SwitchAccountView",
        component: SwitchAccountView,
        // beforeEnter: [isCurrentInvitationValid],
      },
      {
        path: "instructions",
        beforeEnter: [
          FeedbackSubmittedGaurd,
          isAuthenticatedGuard,
          // FeedbackExpiredGaurd,
          // isCurrentInvitationValid,
        ],
        children: [
          {
            path: "",
            name: "RaterInstructions",
            component: RaterInstructions,
          },

          {
            path: "sample-question",
            name: "SampleQuestionView",
            component: SampleQuestionView,
          },
        ],
      },
      {
        path: "assessments",
        name: "Assessments",
        component: MyAssessments,
      },
      {
        path: "admins",
        name: "AdminListingView",
        component: AdminListingView,
        beforeEnter: [RoleGuard(["super_admin"])],
      },
      {
        path: "assessment",
        beforeEnter: [isAuthenticatedGuard],
        children: [
          {
            path: "instruction",
            name: "RaterInstructions",
            component: RaterInstructions,
          },

          {
            path: "sample-question",
            name: "SampleQuestionView",
            component: SampleQuestionView,
          },
          {
            path: "questions",
            name: "FeedbackView",
            component: FeedbackView,
          },
        ],
      },
      {
        path: "resources",
        name: "Resources",
        component: ManageResourcesView,
        beforeEnter: [RoleGuard(["super_admin", "admin"])],
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
