<script setup lang="ts">
import { AxiosError } from "axios";
import { appContainer } from "../../../container";
import { ErrorResponse } from "../../../interface/ErrorInterface";
import { InvitedUserInterface, Role } from "../../../interface/UserInterface";
import { LoadingService } from "../../../services/loadingService";
import { CompanyService } from "../../../services/manageCompanyService";
import { ToastService } from "../../../services/toastService";
import { UserService } from "../../../services/userService";
import { ref } from "vue";
import ValidationErrorComponent from "../../../components/ValidationErrorComponent.vue";

const props = defineProps<{
  invitation: InvitedUserInterface;
}>();
const emit = defineEmits(["close", "delete"]);

const userService = appContainer.resolve(UserService);
const companyService = appContainer.resolve(CompanyService);

const loadingService = appContainer.resolve(LoadingService);
const toastService = appContainer.resolve(ToastService);

const validationErrors = ref<ErrorResponse>();

async function deleteUser() {
  const loader = await loadingService.show();
  if (props.invitation.company_id) {
    companyService
      .deleteCompanyInvitation(props.invitation.id, props.invitation.company_id)
      .then(() => {
        toastService.success("Invitation Deleted Successfully");
        emit("delete");
      })
      .catch((err) => {
        const error = err as AxiosError;
        const errorResponse = error.response?.data as ErrorResponse;
        if (errorResponse?.errors) {
          const flattenedErrors = Object.values(errorResponse.errors).flat();
          toastService.error(flattenedErrors.join("<br>"));
        } else {
          toastService.error("Unable to Delete Invitation");
        }
      })
      .finally(() => loader.hide());
  } else {
    userService
      .deleteInvitation(props.invitation.id)
      .then(() => {
        toastService.success("Invitation Deleted Successfully");
        emit("delete");
      })
      .catch((err) => {
        const error = err as AxiosError;
        const errorResponse = error.response?.data as ErrorResponse;
        if (errorResponse?.errors) {
          const flattenedErrors = Object.values(errorResponse.errors).flat();
          toastService.error(flattenedErrors.join("<br>"));
        } else {
          toastService.error("Unable to Delete Invitation");
        }
      })
      .finally(() => loader.hide());
  }
}
</script>

<template>
  <div class="modal-backdrop">
    <div class="modal" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <ValidationErrorComponent
              v-if="validationErrors"
              :validations="validationErrors"
            ></ValidationErrorComponent>
            <p class="text-center my-4">
              Are you sure you want to remove
              <strong class="text-capitalize">
                {{ invitation.invited_user.first_name }}
                {{ invitation.invited_user.last_name }}
              </strong>
              as
              <strong class="text-capitalize">{{
                invitation.invitation_type
              }}</strong>
              <span
                v-if="
                  invitation.invitation_type === Role.Participants ||
                  invitation.invitation_type === Role.Raters
                "
              >
                for
                {{ invitation.invited_for_user?.first_name }}
                {{ invitation.invited_for_user?.last_name }}?
              </span>
            </p>

            <small class="text-danger text-center d-block">
              **Note: This only removes the current invitation, not the actual
              user from the system**
            </small>
            <div class="d-flex align-items-center justify-content-evenly p-4">
              <button
                type="button"
                class="btn btn-outline-primary"
                @click="emit('close')"
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-danger text-white"
                @click="deleteUser"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
button {
  &:hover {
    color: #fff;
  }
}
</style>
