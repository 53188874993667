<script setup lang="ts">
import { InvitedUserInterface, Role } from "../../interface/UserInterface";
import ProfileIconComponent from "../../components/ProfileIconComponent.vue";
import router from "../../routes";
import { useUserStore } from "../../stores/UserStore";

import { onMounted, ref } from "vue";
import { appContainer } from "../../container";
import { UserService } from "../../services/userService";
import { getDaysLeft } from "../../utils/getDaysleft";

const props = defineProps<{
  assessment: InvitedUserInterface;
}>();

const userStore = useUserStore();
const userService = appContainer.resolve(UserService);

const participant = ref<InvitedUserInterface>();
const daysLeft = ref(0);
onMounted(async () => {
  await getParticipant();
});

function handleAssessment(assessment: InvitedUserInterface) {
  if (
    participant.value?.meta.feedback_done === null &&
    daysLeft?.value >= 0 &&
    !assessment?.meta.feedback_done
  ) {
    userStore.setRater(assessment);
    router.replace("/assessment/instruction");
  }
}

async function getParticipant() {
  await userService
    .getInvitedUser({
      invited_user: props.assessment.invited_for_user?.id,
      invitationType: Role.Participants,
      companyId: props.assessment.company_id,
      all: true,
      allUsers: true,
    })
    .then((result) => {
      participant.value = result.items[0];
      daysLeft.value = getDaysLeft(participant.value);
    });
}
</script>
<template>
  <div
    @click="handleAssessment(assessment)"
    :class="
      !assessment?.meta.feedback_done && daysLeft > 0
        ? 'cursor-pointer'
        : 'disabled'
    "
    class="d-flex border-bottom p-md-3 py-2 align-items-center assessment-card"
  >
    <ProfileIconComponent :user="assessment" />
    <div class="d-flex flex-column justify-content-center">
      <span
        class="fw-semibold name text-capitalize d-flex align-items-center gap-2"
      >
        {{ assessment.invited_for_user?.first_name }}
        {{ assessment.invited_for_user?.last_name }}

        <span
          class="completed-badge align-self-start"
          v-if="assessment?.meta.feedback_done"
          >Completed</span
        >
        <span
          class="progress-badge align-self-start"
          v-else-if="!assessment?.meta.feedback_done && daysLeft > 0"
          >In-Progress</span
        >
        <span
          class="expired-badge align-self-start"
          v-else-if="participant?.meta.feedback_done || daysLeft < 0"
          >Expired</span
        >
      </span>

      <span class="email break-word">
        {{ assessment.invited_for_user?.email }}
      </span>
      <div class="role-wrapper d-flex align-items-center mt-1">
        <span> Company Name: {{ assessment.company?.name }}</span>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import "../../theme/variables";

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.assessment-card {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 1rem;

  input {
    cursor: pointer;
    border-color: #d3d3d3;
  }

  .role-wrapper {
    gap: 10px;
    span {
      font-size: 12px;
    }
  }

  .email {
    color: #6e6e6e;
    font-size: 12px;
  }
}

small {
  font-size: 12px;
}

.icon-wrapper {
  background-color: #eaf1fb;
  color: $blue;
  padding: 10px;
  width: 64px;
  height: 64px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 24px;

  .bi-person-fill-gear {
    font-size: 30px;
  }
}
.expired-badge {
  background: rgba(255, 0, 0, 30%);
  color: rgb(255, 0, 0, 70%);
  font-size: 12px;
  padding: 3px 12px;
  border-radius: 25px;
}

.progress-badge {
  background: rgba(58, 125, 68, 30%);
  color: rgb(58, 125, 68, 70%);
  font-size: 12px;
  padding: 3px 12px;
  border-radius: 25px;
}
.completed-badge {
  background: rgba(92, 179, 56, 30%);
  color: rgba(92, 179, 56, 70%);
  font-size: 12px;
  padding: 3px 12px;
  border-radius: 25px;
}

@media (max-width: 767px) {
  .w-150 {
    max-width: 150px;
  }
}
</style>
