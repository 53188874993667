import type { NavigationGuardWithThis } from "vue-router";
// import { CurrentInvitationStore } from "../stores/CurrentInvtationStore";
import { useUserStore } from "../stores/UserStore";
import { ActiveRolesStore } from "../stores/ActiveRolesStore";
import { useIsFrozenStore } from "../stores/IsQuestionFrozenStore";
import { appContainer } from "../container";
import { AuthService } from "../services/authService";

export const initGlobalState: NavigationGuardWithThis<undefined> = async () => {
  const authService = appContainer.resolve(AuthService);

  const userStore = useUserStore();
  // const currentInvitationStore = CurrentInvitationStore();
  const activeRoleStore = ActiveRolesStore();
  const questionStore = useIsFrozenStore();

  await userStore.getUser();
  // currentInvitationStore.getCurrentInvitation();
  await activeRoleStore.getActiveRole();

  await questionStore.getQuestionStatus();

  if (!!userStore.user && !!activeRoleStore.activeRoles) return true;
  else authService.logout();
};
