<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { appContainer } from "../container";
// import { AuthService } from "../services/authService";
import { LoadingService } from "../services/loadingService";
import { UserService } from "../services/userService";
import { ActiveRolesStore } from "../stores/ActiveRolesStore";
import {
  // AllRaterType,
  DashboardInterface,
  Roles,
} from "../interface/UserInterface";
import StatusCard from "../components/StatusCard.vue";
// import router from "../routes";
// import { StorageKeys, StorageService } from "../services/storage.service";
// import { getDaysLeft } from "../utils/getDaysleft";

const activeRoleStore = ActiveRolesStore();
// const authService = appContainer.resolve(AuthService);
const userService = appContainer.resolve(UserService);
const loaderService = appContainer.resolve(LoadingService);
// const storageService = appContainer.resolve(StorageService);

const dashboardInfo = ref<DashboardInterface | null>(null);
// const raterCount = ref(0);

// const selfRater = ref<InvitedUserInterface>();

// const daysLeft = ref(0);

onMounted(async () => {
  await activeRoleStore.getActiveRole();
  const loader = await loaderService.show();
  await userService
    .getDashboardData()
    .then((result) => (dashboardInfo.value = result))
    .finally(() => {
      loader.hide();
    });
});

const hasMultipleRoles = computed(
  () => activeRoleStore.activeRoles.length >= 2,
);

// async function initDashboard() {
//   const loader = await loaderService.show();

//   await activeRoleStore.getActiveRole();

//   if (!activeRoleStore.activeRoles.includes(CurrentRole.SuperAdmin)) {
//     await currentInvitationStore.getCurrentInvitation();

//     currentInvitation.value = currentInvitationStore.currentInvitation;
//   }

//   // if (activeRoleStore.activeRoles.includes(CurrentRole.Participant)) {
//   //   await getRaterCount();
//   //   await checkSelfRated();
//   //   daysLeft.value = getDaysLeft(
//   //     currentInvitationStore.currentInvitation as InvitedUserInterface,
//   //   );
//   // }
//   // else
//   if (activeRoleStore.activeRoles.includes(CurrentRole.SuperAdmin)) {
//   }
//   loader.hide();
// }

// async function getRaterCount() {
//   raterCount.value = (
//     await userService.getInvitedUser({
//       invitationType: Role.Raters,
//       invited_for: authService.user()?.id,
//       all: true,
//       ...(currentInvitationStore.currentInvitation?.company_id
//         ? {
//             companyId: currentInvitationStore.currentInvitation?.company_id,
//           }
//         : {}),
//       adminId: currentInvitationStore.currentInvitation?.invited_for_user?.id,
//     })
//   ).items.length;
// }
// async function checkSelfRated() {
//   selfRater.value = await userService
//     .getInvitedUser({
//       invited_user: currentInvitation.value?.invited_user.id,
//       invitationType: Role.Raters,
//       ...(currentInvitationStore.currentInvitation?.company_id
//         ? {
//             companyId: currentInvitationStore.currentInvitation?.company_id,
//           }
//         : {}),
//     })
//     .then((result) =>
//       result.items.find(
//         (item) =>
//           item.rater_type === AllRaterType.Self &&
//           currentInvitationStore.currentInvitation?.invited_for_user?.id ===
//             item.meta.admin_id,
//       ),
//     );
// }

// async function handleRateYourSelf() {
//   const selfRater = await userService
//     .getInvitedUser({
//       invitationType: Role.Raters,
//       invited_for: authService.user()?.id,
//       all: true,
//       ...(currentInvitationStore.currentInvitation?.company_id
//         ? {
//             companyId: currentInvitationStore.currentInvitation?.company_id,
//           }
//         : {}),
//     })
//     .then((result) => {
//       return result.items.find((item) => item.rater_type === AllRaterType.Self);
//     });
//   await authService.fetchUser();
//   const activeRole = authService
//     .user()
//     ?.roles.filter((role) => role.role_name === Role.Raters)[0];
//   await storageService.set(StorageKeys.Current_Invitation, selfRater);

//   await storageService.set(StorageKeys.Active_Roles, activeRole);
//   await currentInvitationStore.getCurrentInvitation();
//   await activeRoleStore.getActiveRole();
//   router.replace("instructions");
// }
function visibleTo(roles: string[]) {
  return activeRoleStore.activeRoles?.some((item) => roles.includes(item));
}
</script>

<template>
  <template v-if="dashboardInfo">
    <!-- <div class="d-flex align-items-center d-md-none mb-4">
      <small class="text-body-tertiary me-2">You are signed in as :</small>
      <p class="mb-0">{{ activeRoleStore.activeRole?.display_name }}</p>
    </div> -->
    <h2 class="mb-4 fw-medium fs-4">Statistics</h2>
    <div class="stats-wrapper w-100">
      <template v-if="visibleTo([Roles.SuperAdmin, Roles.Admin])">
        <h3
          v-if="
            hasMultipleRoles &&
            !activeRoleStore.activeRoles.includes(
              Roles.Admin && Roles.SuperAdmin,
            )
          "
          class="mb-4 fw-medium fs-5"
        >
          {{
            activeRoleStore.activeRoles.includes(Roles.SuperAdmin)
              ? "Super Admin"
              : "Admin"
          }}
        </h3>
        <div class="d-flex flex-wrap mb-5">
          <StatusCard
            :title="
              dashboardInfo.super_admin
                ? dashboardInfo.super_admin.total_users.title
                : dashboardInfo.admin.total_users.title
            "
            :value="
              dashboardInfo.super_admin
                ? dashboardInfo.super_admin.total_users.data
                : dashboardInfo.admin.total_users.data
            "
            icon="bi-person"
          />
          <StatusCard
            :title="
              dashboardInfo.super_admin
                ? dashboardInfo.super_admin?.total_companies.title
                : dashboardInfo.admin?.total_companies.title
            "
            :value="
              dashboardInfo.super_admin
                ? dashboardInfo.super_admin?.total_companies.data
                : dashboardInfo.admin?.total_companies.data
            "
            icon="bi-buildings"
          />
          <StatusCard
            :title="
              dashboardInfo.super_admin
                ? dashboardInfo.super_admin?.total_active_sessions.title
                : dashboardInfo.admin?.total_active_sessions.title
            "
            :value="
              dashboardInfo.super_admin
                ? dashboardInfo.super_admin.total_active_sessions.data
                : dashboardInfo.admin.total_active_sessions.data
            "
            icon="bi-file-earmark-spreadsheet"
          />
          <StatusCard
            :title="
              dashboardInfo.super_admin
                ? dashboardInfo.super_admin?.last_week_feedbacks.title
                : dashboardInfo.admin?.last_week_feedbacks.title
            "
            :value="
              dashboardInfo.super_admin
                ? dashboardInfo.super_admin.last_week_feedbacks.data
                : dashboardInfo.admin.last_week_feedbacks.data
            "
            icon="bi-file-earmark-spreadsheet"
          />
        </div>
      </template>

      <!-- <template v-if="activeRoleStore.activeRoles?.includes(CurrentRole.Admin)">
        <StatusCard
          title="Total Participants Invited by You"
          :value="currentInvitation!.item_count.toString()"
          icon="bi-person"
        />
        <StatusCard
          v-if="currentInvitation?.meta.allowed_participants"
          title="Allowed Participants"
          :value="`${currentInvitation.item_count}/${currentInvitation?.meta.allowed_participants}`"
          icon="bi-people-fill"
        />
      </template> -->
      <template v-if="visibleTo([Roles.Participant])">
        <h3 v-if="hasMultipleRoles" class="mb-4 fw-medium fs-5">Participant</h3>
        <div class="d-flex flex-wrap mb-5">
          <StatusCard
            :title="
              dashboardInfo.participant
                ? dashboardInfo.participant.days_left.title
                : ''
            "
            :value="
              dashboardInfo.participant.days_left?.data > 0
                ? dashboardInfo.participant.days_left.data
                : 0
            "
            icon="bi-clock"
          />
          <StatusCard
            :title="
              dashboardInfo.participant
                ? dashboardInfo.participant.feedback_completion_count.title
                : ''
            "
            :value="
              dashboardInfo.participant
                ? dashboardInfo.participant.feedback_completion_count.data
                : 0
            "
            icon="bi-person"
          />
          <StatusCard
            :title="
              dashboardInfo.participant
                ? dashboardInfo.participant.supervisor.title
                : ''
            "
            :value="
              dashboardInfo.participant
                ? dashboardInfo.participant.supervisor.data
                : 0
            "
            icon="bi-person-lines-fill"
          />
          <StatusCard
            :title="
              dashboardInfo.participant
                ? dashboardInfo.participant.self.title
                : ''
            "
            :value="
              dashboardInfo.participant
                ? dashboardInfo.participant.self.data
                : 0
            "
            icon="bi-person-fill"
          />
          <StatusCard
            :title="
              dashboardInfo.participant
                ? dashboardInfo.participant.direct_report.title
                : ''
            "
            :value="
              dashboardInfo.participant
                ? dashboardInfo.participant.direct_report.data
                : 0
            "
            icon="bi-person"
          />
          <StatusCard
            :title="
              dashboardInfo.participant
                ? dashboardInfo.participant.peer.title
                : ''
            "
            :value="
              dashboardInfo.participant
                ? dashboardInfo.participant.peer.data
                : 0
            "
            icon="bi-person"
          />
          <StatusCard
            :title="
              dashboardInfo.participant
                ? dashboardInfo.participant.other.title
                : ''
            "
            :value="
              dashboardInfo.participant
                ? dashboardInfo.participant.other.data
                : 0
            "
            icon="bi-person"
          />
        </div>
      </template>
      <template v-if="visibleTo([Roles.Rater])">
        <h3 v-if="hasMultipleRoles" class="mb-4 fw-medium fs-5">Rater</h3>
        <div class="d-flex flex-wrap mb-5">
          <StatusCard
            :title="
              dashboardInfo.rater
                ? dashboardInfo.rater.total_feedbacks.title
                : ''
            "
            :value="
              dashboardInfo.rater ? dashboardInfo.rater.total_feedbacks.data : 0
            "
            icon="bi-person"
          />
          <StatusCard
            :title="
              dashboardInfo.rater
                ? dashboardInfo.rater.completed_feedbacks.title
                : ''
            "
            :value="
              dashboardInfo.rater
                ? dashboardInfo.rater.completed_feedbacks.data
                : ''
            "
            icon="bi-clipboard-check"
          />
        </div>
      </template>
      <!-- <template v-if="activeRoleStore.activeRoles.includes(Role.Participants)">
        <StatusCard
          title="Total Raters Invited by You"
          :value="`${currentInvitation!.item_count.toString()}/${raterCount}`"
          icon="bi-person"
        />
        <StatusCard
          title="Days Left Before Assessment Ends"
          :value="daysLeft"
          icon="bi-clock"
        />
        <StatusCard
          title="Assessment Completed by Raters"
          :value="`${currentInvitation!.feedback_completion_count}/${currentInvitation?.item_count}`"
          icon="bi-clipboard-check"
        />
      </template> -->
    </div>
    <!-- <template v-if="activeRoleStore.activeRole?.role_name === 'participant'">
      <div class="mt-5" v-if="selfRater && !selfRater.meta.feedback_done">
        You have {{ daysLeft }} days left to rate yourself.
        <button
          class="btn d-block btn-secondary text-white mt-3"
          @click="handleRateYourSelf"
        >
          Rate Yourself
        </button>
      </div>
    </template> -->
  </template>
</template>

<style lang="scss" scoped>
@import "../theme/variables";
.stats-wrapper > div {
  gap: 10px;
}
</style>
