import { NavigationGuardWithThis } from "vue-router";
import { ActiveRolesStore } from "../stores/ActiveRolesStore";

export const RoleGuard = (
  allowedRoles: string[],
): NavigationGuardWithThis<undefined> => {
  return async () => {
    const RolesStore = ActiveRolesStore();
    await RolesStore.getActiveRole();

    return RolesStore.activeRoles.some((role) => allowedRoles.includes(role))
      ? true
      : "/404";
  };
};
