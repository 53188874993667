<script setup lang="ts">
import { onMounted, ref } from "vue";
import { appContainer } from "../../container";
import {
  AllRaterType,
  InvitedUserInterface,
  Role,
} from "../../interface/UserInterface";
import router from "../../routes";
import { UserService } from "../../services/userService";
import { LoadingService } from "../../services/loadingService";
import { getDaysLeft } from "../../utils/getDaysleft";
import { useUserStore } from "../../stores/UserStore";

const userStore = useUserStore();
const userService = appContainer.resolve(UserService);
const loadingService = appContainer.resolve(LoadingService);

const participant = ref<InvitedUserInterface>();
const daysLeft = ref<number | null>(0);
const isLoading = ref(true);

onMounted(async () => {
  await userStore.getRater();
  await getParticipantData();
});

async function getParticipantData() {
  const loader = await loadingService.show();
  await userService
    .getInvitedUser({
      companyId: userStore.invitation?.company_id,
      invited_user: userStore.invitation?.invited_for_user?.id,
      invited_for: userStore.invitation?.meta.admin_id,
      invitationType: Role.Participants,
    })
    .then((result) => {
      isLoading.value = false;
      participant.value = result.items[0];
      daysLeft.value = getDaysLeft(result.items[0]);
    })
    .finally(() => loader.hide());
}

const formatDate = (dateString: Date | string) => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  return date.toLocaleDateString("en-US", options);
};
</script>
<template>
  <div v-if="!isLoading" class="page-wrapper h-100 position-relative">
    <div
      class="d-flex justify-content-between align-items-center flex-wrap mb-4"
    >
      <h2 class="fw-medium fs-4">Instructions</h2>
    </div>
    <div class="instructions">
      <p>
        Thank you for your participation in the Trusted Leader 360° Assessment.
        This tool is designed to provide objective feedback through numerical
        scoring on key aspects of leadership.
      </p>

      <p><strong>Note: This is a Developmental tool</strong></p>
      <ul>
        <li>
          Use <strong>thoughtful</strong> and <strong>balanced</strong> ratings,
          reflecting both strengths <strong>and</strong> areas for growth.
        </li>
        <li>
          Consider real examples from your interactions to illustrate their
          behaviors and impact.
        </li>
        <li>
          Honest, constructive feedback fosters self-awareness and development.
        </li>
      </ul>
      <p>
        <strong>Rating for: </strong
        >{{
          userStore.invitation?.rater_type === AllRaterType.Self
            ? "Self"
            : `${userStore.invitation?.invited_for_user?.first_name} ${userStore.invitation?.invited_for_user?.last_name}`
        }}
      </p>
      <p>
        <strong>Days left for Assessment completion: </strong>{{ daysLeft }}
      </p>
      <p class="m-0">Time Stamps:</p>
      <ul>
        <li>
          <strong>Assessment Started on:</strong>
          {{ participant ? formatDate(participant.sent_on) : "N/A" }}
        </li>
        <li>
          <strong>Assessment Completion Date:</strong>
          {{
            participant
              ? formatDate(participant.meta.feedback_completion_date)
              : "N/A"
          }}
        </li>
      </ul>
      <button
        class="btn btn-secondary text-white px-4 start-btn my-5"
        @click="router.push('/assessment/sample-question')"
      >
        Review Samples
      </button>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.brand-logo {
  height: 50px;
  img {
    height: 100%;
    width: 100%;
  }
}
.instructions {
  max-width: 800px;
}

.page-footer {
  bottom: 50px;
}
@media (max-width: 575px) {
  .start-btn {
    padding: 6px;
    font-size: 14px;
  }
}
</style>
