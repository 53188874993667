import { inject, injectable } from "inversify";
import { ApiCallService } from "./apiCall.service";
import { StorageKeys, StorageService } from "./storage.service";
import axios, { Axios } from "axios";
import type { AxiosError, AxiosInstance } from "axios";
import { TokenInterface } from "../interface/TokenInterface";
import { UserInterface } from "../interface/UserInterface";
import { ref } from "vue";
import router from "../routes";
import { useIsFrozenStore } from "../stores/IsQuestionFrozenStore";

@injectable()
export class AuthService {
  /**
   * The current logged-in user or null
   * @protected
   */

  public userState = ref<UserInterface | null>();

  /**
   * True when all auth service initialization is completed
   * @protected
   */

  protected initializationCompleted = false;
  constructor(
    @inject(ApiCallService) public apiCallService: ApiCallService,
    @inject(StorageService) public storage: StorageService,
    @inject(Axios) public axios: AxiosInstance,
  ) {}

  protected tokenState = ref<TokenInterface | null>();

  public token() {
    return this.tokenState.value;
  }

  public user() {
    return this.userState.value;
  }

  public async login(code: string): Promise<UserInterface | void> {
    // @if no verifier found then need to handle this as an error
    return axios
      .post<TokenInterface>(this.apiCallService.url("oauth/token"), {
        code_verifier: await this.storage.get(StorageKeys.CODE_VERIFIER),
        code,
        grant_type: "pkce",
      })
      .then((response) => {
        this.storage.set(StorageKeys.TOKEN, response.data);
        this.storage.remove(StorageKeys.CODE_CHALLENGE);
        this.storage.remove(StorageKeys.CODE_VERIFIER);
        return this.fetchUser();
      });
  }

  public async fetchUser(): Promise<UserInterface | void> {
    return axios
      .get<UserInterface>(this.apiCallService.apiUrl("users"))
      .then((result) => {
        return result.data;
      })
      .then(async (result) => {
        await this.storage.set(StorageKeys.USER, result);
        this.userState.value = result;
        const roles = result.roles.map((role) => role.role_name);
        this.storage.set(StorageKeys.Active_Roles, roles);
        return result;
      })
      .catch((err) => {
        const error = err as AxiosError;
        if (error.response?.status === 401 || error.response?.status === 500) {
          this.storage.remove(StorageKeys.Current_Invitation);
          this.storage.remove(StorageKeys.Active_Roles);
          this.storage.remove(StorageKeys.USER);
          this.storage.remove(StorageKeys.TOKEN);
        }
      });
  }

  /**
   * Initializes auth state
   */
  public async initAuthState(): Promise<boolean> {
    if (this.initializationCompleted) {
      return true;
    }
    const questionStore = useIsFrozenStore();

    const token = await this.storage.get<TokenInterface>(StorageKeys.TOKEN);
    if (!token) {
      this.tokenState.value = null;
      this.userState.value = null;
      this.initializationCompleted = true;
      return this.initializationCompleted;
    }
    const userState = await this.storage.get<UserInterface>(StorageKeys.USER);
    if (!userState) {
      this.tokenState.value = null;
      this.userState.value = null;
      this.initializationCompleted = true;
      return this.initializationCompleted;
    }

    this.userState.value = userState;
    const activeRoles = this.userState.value.roles.map(
      (role) => role.role_name,
    );
    this.storage.set(StorageKeys.Active_Roles, activeRoles);
    await questionStore.getQuestionStatus();
    this.initializationCompleted = true;

    return this.initializationCompleted;
  }

  public async logout(): Promise<{ status: "ok" }> {
    const token = await this.storage.get<TokenInterface>(StorageKeys.TOKEN);
    return axios
      .post(
        this.apiCallService.url("oauth/logout"),
        {},
        {
          headers: {
            Authorization: `Bearer ${token?.access_token}`,
          },
        },
      )
      .then((result) => {
        this.userState.value = null;
        this.tokenState.value = null;
        this.storage.removeAll();
        router.push("/login");
        return result.data;
      });
  }
}
