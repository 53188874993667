<script setup lang="ts">
import { onMounted, ref } from "vue";
import { InvitedUserInterface, Role } from "../../interface/UserInterface";
import { appContainer } from "../../container";
import { UserService } from "../../services/userService";
import UserCardListing from "../manage-users/components/UserListingCard.vue";
import { ActiveYearStore } from "../../stores/ActiveYearStore";
import { watch } from "vue";
import { PagingInterface } from "../../interface/PaginationInterface";
import PaginationComponent from "../../components/PaginationComponent.vue";
import { LoadingService } from "../../services/loadingService";

const allAdmins = ref<InvitedUserInterface[]>();
const userService = appContainer.resolve(UserService);
const loadingService = appContainer.resolve(LoadingService);
const activeYearStore = ActiveYearStore();
const isLoading = ref(true);
const paginatedAdmins = ref<PagingInterface<InvitedUserInterface>>();

onMounted(async () => {
  await getAdminList();
});
async function getAdminList(
  pageConfig: { page: number; offset: number } = { page: 1, offset: 8 },
) {
  const loader = await loadingService.show();
  return userService
    .getInvitedUser(
      {
        invitationType: Role.Admin,
        year: activeYearStore.activeYear,
        invitation_sent: true,
      },
      pageConfig,
    )
    .then((result) => {
      paginatedAdmins.value = result;
      allAdmins.value = result.items;
      isLoading.value = false;
    })
    .finally(() => loader.hide());
}

watch(
  () => activeYearStore.activeYear,
  () => getAdminList(),
);

function updatePage(pageNumber: number) {
  if (pageNumber === paginatedAdmins.value?.page) {
    return;
  }
  getAdminList({ page: pageNumber, offset: 8 });
}
</script>
<template>
  <h2 class="fw-medium fs-4 mb-3">Manage Reports</h2>
  <h3 class="fw-medium fs-5 mb-3 ff-montserrat">List of Admins</h3>
  <template v-if="!isLoading && allAdmins?.length">
    <UserCardListing
      v-for="admin of allAdmins"
      :showDetail="false"
      :user="admin"
      :key="admin.id"
      :forReports="true"
    />
    <PaginationComponent
      v-if="paginatedAdmins?.totalItems"
      :totalRows="paginatedAdmins?.totalItems"
      :currentPage="paginatedAdmins?.page"
      :perPage="paginatedAdmins?.offset"
      @pageChange="updatePage"
    />
  </template>
  <div
    class="no-result d-flex align-items-center justify-content-center text-opacity-50 text-danger vh-50"
    v-else
  >
    No Records Found
  </div>
</template>
