<script lang="ts" setup>
import { onMounted, ref, watch } from "vue";
import { appContainer } from "../container";
import { AuthService } from "../services/authService";
import {
  AllRaterType,
  InvitedUserInterface,
  Role,
  UserInterface,
} from "../interface/UserInterface";
import ProfileIconComponent from "../components/ProfileIconComponent.vue";
import { CurrentInvitationStore } from "../stores/CurrentInvtationStore";
import { UserService } from "../services/userService";

const props = defineProps<{ user: InvitedUserInterface }>();
const emit = defineEmits<{
  (event: "invitationSelected", invitationId: number): void;
}>();

const authService = appContainer.resolve(AuthService);
const userService = appContainer.resolve(UserService);
const currentInvitationStore = CurrentInvitationStore();

const currentInvitationId = ref<number | null>(null);
const currentUser = ref<UserInterface | null>();
const currentAdmin = ref<InvitedUserInterface | null>();
const userStatus = ref<string>("");

onMounted(async () => {
  currentUser.value = authService.user();
  currentInvitationStore.getCurrentInvitation();
  await getAssessmentStatus();
  if (props.user.rater_type === AllRaterType.Self) {
    console.log("Self");
    await getAdminName();
  }
});

watch(currentInvitationId, () => {
  if (currentInvitationId.value) {
    emit("invitationSelected", currentInvitationId.value);
  }
});
async function getAdminName() {
  await userService
    .getInvitedUser({
      invited_user: props.user.meta.admin_id,
      year: props.user.meta.year,
      invitationType: Role.Admin,
      allUsers: true,
      ...(props.user.company_id
        ? {
            companyId: props.user.company_id,
          }
        : {}),
    })
    .then((result) => (currentAdmin.value = result.items[0]));
}

async function getAssessmentStatus() {
  if (
    props.user.invitation_type === Role.Raters &&
    !props.user.meta.feedback_done
  )
    userService
      .getInvitedUser({
        invited_for: props.user.meta.admin_id,
        ...(props.user.company_id
          ? {
              companyId: props.user.company_id,
            }
          : {}),
        invitationType: Role.Participants,
        invited_user: props.user.invited_for_user?.id,
        all: true,
      })
      .then((result) => {
        if (
          new Date().toISOString() >
          result.items[0].meta.feedback_completion_date
        ) {
          userStatus.value = "Expired";
        } else if (
          new Date().toISOString() <=
          result.items[0].meta.feedback_completion_date
        ) {
          userStatus.value = "Pending";
        }
      });
}
</script>

<template>
  <div class="user-card d-flex align-items-center gap-4 mb-3">
    <input
      type="radio"
      class="form-check-input"
      name="invitation"
      v-model="currentInvitationId"
      :value="props.user.id"
      :checked="user.id === currentInvitationStore.currentInvitation?.id"
      :id="user.id.toString()"
    />
    <label class="user-info d-block" :for="user.id.toString()">
      <div class="d-flex align-items-center gap-3">
        <ProfileIconComponent :user="user" />
        <div class="w-150">
          <div class="text-capitalize d-flex align-items-center gap-2">
            {{ user.invitation_type }}
            <p
              class="mb-0"
              v-if="
                user.invitation_type === Role.Participants &&
                new Date().toISOString() > user.meta.feedback_completion_date
              "
            >
              <small class="fs-bold text-danger">Expired</small>
            </p>
            <p
              class="mb-0"
              v-if="
                user.invitation_type === Role.Raters && user.meta.feedback_done
              "
            >
              <small class="fs-bold text-secondary">Done</small>
            </p>
            <p
              class="mb-0"
              v-if="
                user.invitation_type === Role.Raters && !user.meta.feedback_done
              "
            >
              <small
                class="fs-bold"
                :class="{
                  'text-danger': userStatus === 'Expired',
                  'text-warning': userStatus === 'Pending',
                }"
                >{{ userStatus }}</small
              >
            </p>
          </div>
          <small
            v-if="user.rater_type === AllRaterType.Self"
            class="text-black-50"
          >
            For: Self
            <small class="text-black-50 d-block">
              Admin: {{ currentAdmin?.invited_user.first_name }}
              {{ currentAdmin?.invited_user.last_name }}</small
            >
          </small>
          <small
            v-if="user.rater_type != AllRaterType.Self && user.invited_for_user"
            class="text-black-50"
          >
            For: {{ user.invited_for_user.first_name }}
            {{ user.invited_for_user.last_name }}</small
          >
          <p class="mb-0">
            <small v-if="user.company" class="text-black-50">
              Company: {{ user.company.name }}</small
            >
          </p>
        </div>
      </div>
    </label>
  </div>
</template>
<style lang="scss" scoped>
@import "../theme/variables";

.user-card {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 1rem;

  input {
    cursor: pointer;
    border-color: #d3d3d3;
  }
}

.user-info {
  cursor: pointer;
}

small {
  font-size: 12px;
}

.icon-wrapper {
  background-color: #eaf1fb;
  color: $blue;
  padding: 10px;
  width: 64px;
  height: 64px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 24px;

  .bi-person-fill-gear {
    font-size: 30px;
  }
}
@media (max-width: 767px) {
  .w-150 {
    max-width: 150px;
  }
}
</style>
