<script setup lang="ts">
import { onMounted, ref, watch } from "vue";
import {
  InvitedUserInterface,
  Role,
  feedbackStatus,
} from "../../interface/UserInterface";
import { appContainer } from "../../container";
import { UserService } from "../../services/userService";
import { useRoute } from "vue-router";
import UserListingCard from "../manage-users/components/UserListingCard.vue";
import { ActiveYearStore } from "../../stores/ActiveYearStore";
import { LoadingService } from "../../services/loadingService";

// import { getDaysLeft } from "../../utils/getDaysleft";

const userService = appContainer.resolve(UserService);
const loadingService = appContainer.resolve(LoadingService);
const selectedStatus = ref<feedbackStatus | null>(null);

onMounted(async () => {
  await getCompanyParticipant();
});

const route = useRoute();

const participants = ref<InvitedUserInterface[]>();
const activeYearStore = ActiveYearStore();

const isLoading = ref(true);

async function getCompanyParticipant() {
  const loader = await loadingService.show();
  await userService
    .getInvitedUser({
      invitationType: Role.Participants,
      companyId: Number(route.params.id),
      all: true,
      invitation_sent: true,
      // recently_completed: true,
      feedback: selectedStatus.value,
      year: activeYearStore.activeYear,
    })
    .then((result) => {
      participants.value = result.items.length ? result.items : [];
      isLoading.value = false;
    })
    .finally(() => {
      loader.hide();
      isLoading.value = false;
    });
}

watch([() => activeYearStore.activeYear, () => selectedStatus.value], () => {
  getCompanyParticipant();
});
</script>
<template>
  <template v-if="!isLoading">
    <p class="fw-medium fs-5">Participants Listing</p>
    <ul
      class="nav nav-pills mb-3 justify-content-between justify-content-sm-start"
    >
      <li class="nav-item">
        <button
          class="nav-link"
          :class="{ active: selectedStatus === null }"
          @click="selectedStatus = null"
        >
          All
        </button>
      </li>
      <li class="nav-item">
        <button
          class="nav-link"
          :class="{ active: selectedStatus === feedbackStatus.Ongoing }"
          @click="selectedStatus = feedbackStatus.Ongoing"
        >
          Ongoing
        </button>
      </li>
      <li class="nav-item">
        <button
          class="nav-link"
          :class="{ active: selectedStatus === feedbackStatus.Completed }"
          @click="selectedStatus = feedbackStatus.Completed"
        >
          Completed
        </button>
      </li>
    </ul>

    <div class="all-participant" v-if="participants?.length">
      <UserListingCard
        v-for="participant of participants"
        :showDetail="false"
        :user="participant"
        :key="participant.id"
        :forReports="true"
        @done="getCompanyParticipant()"
      />
    </div>
    <div
      class="no-result d-flex align-items-center justify-content-center text-opacity-50 text-danger vh-50"
      v-else
    >
      No Records Found
    </div>
  </template>
</template>

<style scoped lang="scss">
.nav-link {
  border-radius: 0;
  border-bottom: 3px solid transparent;
  &.active {
    background-color: transparent;
    color: var(--bs-primary);
    border-color: var(--bs-primary);
  }
}
@media (max-width: 575px) {
  .nav-link {
    font-size: 14px;
  }
}
</style>
