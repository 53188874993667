<script setup lang="ts">
import { onMounted, ref } from "vue";
import { appContainer } from "../../container";
import { AuthService } from "../../services/authService";
import { CurrentInvitationStore } from "../../stores/CurrentInvtationStore";
import { Role } from "../../interface/UserInterface";
import { UserService } from "../../services/userService";
import { StorageKeys, StorageService } from "../../services/storage.service";
import router from "../../routes";
const authService = appContainer.resolve(AuthService);
const currentInvitationStore = CurrentInvitationStore();
const userService = appContainer.resolve(UserService);
const storageService = appContainer.resolve(StorageService);

const showSwitchAccount = ref(false);
const isLoading = ref(true);

onMounted(() => {
  currentInvitationStore.getCurrentInvitation();
  hasMultipleRoles();
});

async function hasMultipleRoles() {
  const totalRoles = authService.user()!.roles.length;
  const totalInvitations = await userService
    .getInvitedUser({
      allUsers: true,
      all: true,
      invited_user: authService.user()?.id,
    })
    .then((result) => {
      isLoading.value = false;
      return result.items.filter((item) => item.invitation_sent === true)
        .length;
    });
  showSwitchAccount.value = totalRoles + totalInvitations > 2;
}

function redirectToSwitchAccount() {
  storageService.remove(StorageKeys.Active_Roles);
  storageService.remove(StorageKeys.Current_Invitation);
  router.push("/choose-roles");
}
</script>

<template>
  <div
    v-if="!isLoading"
    class="d-flex align-items-center justify-content-center w-100 text-center px-3"
  >
    <div class="flex-column flex-center">
      <template
        v-if="
          currentInvitationStore.currentInvitation?.invitation_type ===
          Role.Raters
        "
      >
        <h3 class="ff-montserrat text-body-tertiary fs-6">
          The assessment session for
          <strong>
            {{
              currentInvitationStore.currentInvitation?.invited_for_user
                ?.first_name
            }}
            {{
              currentInvitationStore.currentInvitation?.invited_for_user
                ?.last_name
            }}</strong
          >
          has expired.
        </h3>
        <h3 class="ff-montserrat mb-4 text-body-tertiary fs-6">
          You are no longer able to submit your assessment.
        </h3>
      </template>
      <template
        v-if="
          currentInvitationStore.currentInvitation?.invitation_type ===
          Role.Participants
        "
      >
        <h3 class="ff-montserrat text-body-tertiary fs-6">
          Your assessment session ended.
        </h3>
        <h3 class="ff-montserrat mb-4 text-body-tertiary fs-6">
          You will receive your report shortly.
        </h3>
      </template>
      <button
        class="btn btn-primary text-white mb-4"
        v-if="showSwitchAccount"
        @click="redirectToSwitchAccount"
      >
        Switch Roles
      </button>
      <button class="btn btn-danger text-white" @click="authService.logout()">
        Logout
      </button>
    </div>
  </div>
</template>

<style scoped lang="scss">
button {
  padding: 5px 30px;
}
</style>
