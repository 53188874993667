<script setup lang="ts">
import { onBeforeMount, ref, watch } from "vue";
import { appContainer } from "../container";
import router from "../routes";
import { AuthService } from "../services/authService";
import PageHeaderComponent from "./PageHeaderComponent.vue";
import { ToastService } from "../services/toastService";
import { useIsFrozenStore } from "../stores/IsQuestionFrozenStore";
import { ActiveRolesStore } from "../stores/ActiveRolesStore";
import { LoadingService } from "../services/loadingService";
// import { ActiveYearStore } from "../stores/ActiveYearStore";
import { UserService } from "../services/userService";
import { Roles } from "../interface/UserInterface";
import { useUserStore } from "../stores/UserStore";

const authService = appContainer.resolve(AuthService);
const toastService = appContainer.resolve(ToastService);
const loadingService = appContainer.resolve(LoadingService);

const questionStatusStore = useIsFrozenStore();
const activeRoleStore = ActiveRolesStore();
const isLoading = ref(true);
const showSwitchAccount = ref(false);
const activeYear = ref<number>(new Date().getFullYear());
const years = ref<number[]>([]);

const userStore = useUserStore();

// const activeYearStore = ActiveYearStore();

// const menuItems = [
//   {
//     name: "Dashboard",
//     icon: "bi-grid",
//     isActive: isActiveMenuItem("/dashboard"),
//     link: "/dashboard",
//     isVisible: visibleTo(["super_admin", "admin", "participant"]),
//     showToast: false,
//     disabled: false,
//   },
//   {
//     name: "Questions",
//     icon: "bi-list-task",
//     isActive: isActiveMenuItem("/questions"),
//     link: "/questions",
//     isVisible: visibleTo(["super_admin"]),
//     showToast: false,
//     disabled: false,
//   },

//   {
//     name: "Users",
//     icon: "bi-person",
//     isActive: isActiveMenuItem("/users"),
//     link: "/users",
//     isVisible: visibleTo(["super_admin", "admin"]),
//     showToast: false,
//     disabled: false,
//   },

//   // {
//   //   name: "",
//   //   icon: "",
//   //   isActive: isActiveMenuItem(""),
//   //   link: "/",
//   //   isVisible: visibleTo(["super_admin"]),
//   //   showToast: false,
//   //   disabled: false,
//   // },
//   // {
//   //   name: "",
//   //   icon: "",
//   //   isActive: isActiveMenuItem(""),
//   //   link: "/",
//   //   isVisible: visibleTo(["super_admin"]),
//   //   showToast: false,
//   //   disabled: false,
//   // },
//   // {
//   //   name: "",
//   //   icon: "",
//   //   isActive: isActiveMenuItem(""),
//   //   link: "/",
//   //   isVisible: visibleTo(["super_admin"]),
//   //   showToast: false,
//   //   disabled: false,
//   // },
//   // {
//   //   name: "",
//   //   icon: "",
//   //   isActive: isActiveMenuItem(""),
//   //   link: "/",
//   //   isVisible: visibleTo(["super_admin"]),
//   //   showToast: false,
//   //   disabled: false,
//   // },
//   // {
//   //   name: "",
//   //   icon: "",
//   //   isActive: isActiveMenuItem(""),
//   //   link: "/",
//   //   isVisible: visibleTo(["super_admin"]),
//   //   showToast: false,
//   //   disabled: false,
//   // },
// ];

const userService = appContainer.resolve(UserService);

function isActiveMenuItem(path: string, fullCheck = true): boolean {
  return fullCheck
    ? router.currentRoute.value.fullPath === path
    : router.currentRoute.value.fullPath.startsWith(path);
}
function hideMenu(showToast: boolean = false) {
  showSidebar.value = false;
  if (showToast) {
    toastService.error(
      "Please Add and Freeze Questions to Access This Feature ",
      { duration: 5000, position: "top" },
    );
  }
}

function visibleTo(roles: string[]) {
  return activeRoleStore.activeRoles?.some((item) => roles.includes(item));
}

const skipRoutes = ["/login", "/auth-landing, /choose-roles"];

function sidebarVisible() {
  return (
    !skipRoutes.includes(router.currentRoute.value.fullPath) &&
    !router.currentRoute.value.fullPath.startsWith("/login")
  );
}
onBeforeMount(async () => {
  await activeRoleStore.getActiveRole();
  setupYears();
  await hasMultipleRoles();

  await questionStatusStore
    .getQuestionStatus()
    .then(() => (isLoading.value = false));
});

function setupYears() {
  const startYear = 2023;

  years.value = Array.from(
    { length: activeYear.value - startYear + 1 },
    (_, i) => startYear + i,
  );
}
const showSidebar = ref(false);

async function logout() {
  const loader = await loadingService.show();
  await authService.logout().finally(() => loader.hide());
}

async function hasMultipleRoles() {
  const totalRoles = authService.user()!.roles.length;
  const totalInvitations = await userService
    .getInvitedUser({
      allUsers: true,
      all: true,
      invited_user: authService.user()?.id,
    })
    .then(
      (result) =>
        result.items.filter((item) => item.invitation_sent === true).length,
    );
  showSwitchAccount.value = totalRoles + totalInvitations > 2;
}
watch(
  () => authService.user()!.roles,
  async () => await hasMultipleRoles(),
);

// function manageRoportUrlCheck() {
//   const pattern = /^\/manage-reports\/\d+\/participant$/;
//   const currentRoute = router.currentRoute.value.fullPath;
//   return pattern.test(currentRoute);
// }
</script>
<template>
  <button
    v-if="sidebarVisible()"
    class="btn border-0 position-fixed text-white sidebar-close-btn"
    @click="showSidebar = false"
    :class="{ 'd-block': showSidebar }"
  >
    <i class="bi bi-x-lg fs-2"></i>
  </button>
  <div
    class="aside-sidebar h-100 bg-white"
    v-if="sidebarVisible()"
    :class="{ showSidebar }"
  >
    <div class="px-4">
      <router-link
        class="navbar-brand d-flex align-items-center w-100 py-4"
        to="/dashboard"
      >
        <img src="../assets/logo.png" alt="Trusted Leaders Logo" />
      </router-link>
    </div>

    <ul class="navbar-nav" v-if="!isLoading">
      <li
        v-if="visibleTo(['super_admin', 'admin', 'participant', 'rater'])"
        :class="{ active: isActiveMenuItem('/dashboard') }"
        @click="hideMenu(false)"
      >
        <router-link to="/dashboard">
          <i class="bi bi-grid"></i>Dashboard</router-link
        >
      </li>

      <li
        v-if="visibleTo(['super_admin'])"
        :class="{
          active:
            isActiveMenuItem('/questions') ||
            isActiveMenuItem('/preview-feedback'),
        }"
        @click="hideMenu(false)"
      >
        <router-link to="/questions">
          <i class="bi bi-list-task"></i>Questions</router-link
        >
      </li>
      <li
        v-if="visibleTo(['super_admin'])"
        :class="{
          active: isActiveMenuItem('/admins'),
        }"
        @click="hideMenu(false)"
      >
        <router-link to="/admins">
          <i class="bi bi-person-gear"></i>Admins</router-link
        >
      </li>
      <li
        v-if="visibleTo(['super_admin', 'admin'])"
        :class="{
          active:
            isActiveMenuItem('/companies') ||
            isActiveMenuItem('/company', false),
          disabled: !questionStatusStore.isFrozen,
        }"
        @click="hideMenu(!questionStatusStore.isFrozen)"
      >
        <router-link to="/companies">
          <i class="bi bi-buildings"></i>Companies</router-link
        >
      </li>
      <li
        v-if="
          visibleTo([Roles.SuperAdmin]) ||
          (visibleTo([Roles.Admin]) && userStore.user?.certification)
        "
        :class="{
          active: isActiveMenuItem('/resources'),
        }"
      >
        <router-link to="/resources">
          <i class="bi bi-file-earmark-text"></i>Resources</router-link
        >
      </li>
      <li
        v-if="visibleTo(['participant'])"
        :class="{
          active: isActiveMenuItem('/manage-raters'),
        }"
        @click="hideMenu(false)"
      >
        <router-link to="/manage-raters">
          <i class="bi bi-buildings"></i>Manage Raters</router-link
        >
      </li>
      <li
        v-if="visibleTo(['super_admin'])"
        :class="{ active: isActiveMenuItem('/app-settings') }"
        @click="hideMenu(false)"
      >
        <router-link to="/app-settings">
          <i class="bi bi-gear"></i>App Settings</router-link
        >
      </li>

      <li
        v-if="visibleTo(['rater'])"
        :class="{ active: isActiveMenuItem('/assessments') }"
        @click="hideMenu(false)"
      >
        <router-link to="/assessments">
          <i class="bi bi-grid"></i>Feedbacks</router-link
        >
      </li>

      <!-- <div
        class="date-dropdown"
        v-if="
          activeRoleStore.activeRole?.role_name === 'super_admin' &&
          !router.currentRoute.value.fullPath.includes(
            'manage-users/user-detail',
          ) &&
          !manageRoportUrlCheck()
        "
      >
        <select
          class="form-select"
          name="date"
          v-model="activeYear"
          @change="activeYearStore.updateActiveYear(activeYear)"
        >
          <option :value="year" v-for="year in years" :key="year">
            {{ year }}
          </option>
        </select>
      </div> -->
      <li class="mt-auto mb-4">
        <button
          class="logout-btn btn d-flex align-items-center mx-auto text-primary"
          @click="logout()"
        >
          <i class="bi bi-power"></i>Log Out
        </button>
      </li>
    </ul>
  </div>
  <div
    class="app-page"
    :class="{
      'p-0': !sidebarVisible(),
      'vh-100': router.currentRoute.value.fullPath != '/login',
    }"
  >
    <PageHeaderComponent
      @showSidebar="showSidebar = true"
      v-if="sidebarVisible()"
    />
    <RouterView />
  </div>
</template>

<style scoped lang="scss">
@import "../theme/variables";

.date-dropdown {
  margin-top: 50px;
  padding: 0 20px 0 30px;
}

.sidebar-close-btn {
  display: none;
  top: 10px;
  right: 10px;
  z-index: 20;
}

.app-page {
  flex: 1;
  overflow: auto;
  padding-top: 110px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  position: relative;
}

.aside-sidebar {
  width: 250px;
  border-right: 1px solid #d1d9e2;
  transition: transform 200ms ease;
}
.navbar-brand {
  img {
    margin-left: auto;
    margin-right: auto;
    height: 50px;
    object-fit: contain;
  }
}
ul {
  height: calc(100% - 108px);
  overflow: auto;
  li {
    border-left: 10px solid transparent;
    transition: all ease 200ms;
    a {
      padding: 5px 20px;
      display: flex;
      align-items: center;
      gap: 12px;
      cursor: pointer;
      user-select: none;
      color: inherit;
      text-decoration: none;
      font-size: 14px;
    }
    &.active {
      color: $blue;
      font-weight: 600;
      background: rgb($blue, 0.15);
      border-color: $blue;
    }
    &.disabled {
      background-color: #d1d9e2;
      opacity: 0.5;
      cursor: not-allowed;

      a {
        pointer-events: none;
      }
    }
  }
}
i {
  font-size: 22px;
}
.logout-btn {
  gap: 12px;
  border: none !important;
  cursor: pointer;
  user-select: none;
}

.bi-list-ul {
  &::before {
    font-weight: 600 !important;
  }
}

@media (max-width: 991px) {
  .app-page {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .aside-sidebar {
    position: fixed;
    transform: translateX(-100%);
    z-index: 10;
    &.showSidebar {
      transform: translateX(0);
      &::after {
        content: "";
        position: fixed;
        width: calc(100vw - 249px);
        transform: translateX(100%);
        height: 100%;
        right: 0;
        top: 0;
        z-index: 9;
        background-color: rgba(0, 0, 0, 0.662);
      }
    }
  }
}
</style>
