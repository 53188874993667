<script setup lang="ts">
// import { ref } from "vue";
// import { Certification } from "../../../interface/UserInterface";
import { UserService } from "../../../services/userService";
import { appContainer } from "../../../container";
import { LoadingService } from "../../../services/loadingService";
import { ToastService } from "../../../services/toastService";

const userService = appContainer.resolve(UserService);
const loadingService = appContainer.resolve(LoadingService);
const toastService = appContainer.resolve(ToastService);
const emit = defineEmits(["certified", "close"]);

const props = defineProps<{
  userId: number;
}>();

async function handleCertification() {
  const loader = await loadingService.show();
  await userService
    .isCertified(props.userId)
    .then(() => {
      toastService.success("Admin Certified Successfully");
      emit("certified");
    })
    .finally(() => {
      loader.hide();
    });
}
</script>

<template>
  <div class="modal-backdrop">
    <div class="modal" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <p class="text-center mt-4 px-2 fs-5">
              Are you sure you want to certify this admin?
            </p>

            <div
              class="d-flex align-items-center justify-content-evenly p-4 gap-3 flex-wrap"
            >
              <button
                type="button"
                class="btn btn-danger btn-sm text-white"
                @click="emit('close')"
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-secondary btn-sm text-white"
                @click="handleCertification"
              >
                Mark as Certified
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
button {
  width: calc(50% - 1.5rem);
}
@media (max-width: 767px) {
  button {
    width: 100%;
  }
}
</style>
