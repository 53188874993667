import { UserInterface } from "./UserInterface";

export interface CategoryData {
  color?: string | null;
  logo?: string | null;
}

export interface CategoryColors {
  lead: CategoryData;
  develop: CategoryData;
  care: CategoryData;
  eqSelf: CategoryData;
  eqOthers: CategoryData;
}

export interface ThemeInterface {
  id?: number;
  created_at?: Date;
  updated_at?: Date;
  user_id?: number;
  primary_color: string;
  secondary_color: string;
  // tertiary_color: string;
  category_colors: CategoryColors;
  footer_string: string;
  logo_file?: string;
}

export interface LinksInterface {
  id: number;
  created_at: string;
  updated_at: string;
  link_for: LinkFor;
  link: string;
  updated_by: UserInterface;
}

export enum LinkFor {
  Privacy = "privacy",
  Terms = "terms",
}

export enum ThemeCategory {
  Lead = "lead",
  Develop = "develop",
  Care = "care",
  EQSelf = "eqSelf",
  EQOthers = "eqOthers",
}
