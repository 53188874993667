import { UserInterface } from "./UserInterface";

export interface CompanyInterface {
  id: number;
  created_at: Date | string;
  updated_at: Date | string;
  name: string;
  representative_email: string;
  description: string;
  logo_file: string;
  type: CompanyType;
  allowed_feedbacks: number;
}

export interface EmployeeInterface {
  email: string;
  first_name: string;
  last_name: string;
}

export interface CompanyEmployeeInterface {
  id: number;
  created_at: Date | string;
  updated_at: Date | string;
  user: UserInterface;
  company: CompanyInterface;
  company_id: number;
  user_id: number;
}

export interface InviteEmployeeInterface {
  invited_for: null | number;
  invitation_type: string;
  rater_type: null | string;
  duration_days: string | null;
  admin_id: number | null;
}

export enum CompanyType {
  Company = "company",
  Ministry = "ministry",
}
