<script lang="ts" setup>
import { onMounted, ref } from "vue";
import {
  Role,
  InvitedUserInterface,
  UserInterface,
  InviteUserInterface,
  RaterTypeNames,
  DurationDays,
} from "../../../interface/UserInterface";
import { appContainer } from "../../../container";
import { UserService } from "../../../services/userService";
import { debounce } from "../../../utils/debounce";
import { CurrentInvitationStore } from "../../../stores/CurrentInvtationStore";
import { AuthService } from "../../../services/authService";
import { AxiosError } from "axios";
import { ErrorResponse } from "../../../interface/ErrorInterface";
import { LoadingService } from "../../../services/loadingService";
import { CompanyService } from "../../../services/manageCompanyService";
import { ToastService } from "../../../services/toastService";
import ValidationErrorComponent from "../../../components/ValidationErrorComponent.vue";

const emit = defineEmits(["close", "edit"]);

const props = defineProps<{ user: InvitedUserInterface }>();

const userService = appContainer.resolve(UserService);
const authService = appContainer.resolve(AuthService);

const companyService = appContainer.resolve(CompanyService);

const loadingService = appContainer.resolve(LoadingService);
const toastService = appContainer.resolve(ToastService);
const validationErrors = ref<ErrorResponse>();

const currentInvitationStore = CurrentInvitationStore();

const userData = ref<InviteUserInterface>({
  first_name: "",
  last_name: "",
  email: "",
  invited_for: null,
  invitation_type: "",
  rater_type: null,
  duration_days: null,
  admin_id: null,
});

const invitationFor = ref<number | string>("");
const raterTypeValue = ref("");

const isLoading = ref(true);

onMounted(async () => {
  const loader = await loadingService.show();
  await initEditModal().then(() => {
    isLoading.value = false;
    loader.hide();
  });
});

async function initEditModal() {
  userData.value.first_name = props.user.invited_user.first_name;
  userData.value.last_name = props.user.invited_user.last_name;
  userData.value.email = props.user.invited_user.email;
  invitationFor.value = props.user.invited_for_user?.id || "";
  raterTypeValue.value = props.user.rater_type || "";
  userData.value.invitation_type = props.user.invitation_type;
  userData.value.duration_days = props.user.meta.duration_days;
  disabled.value = true;
}

const existingUsers = ref<UserInterface[]>([]);

function updateInputFields() {
  disabled.value = false;
}

function fetchEmails() {
  if (userData.value.email.length === 0) {
    existingUsers.value = [];
    updateInputFields();
    return;
  }

  if (userData.value.email.length >= 3) {
    debounce(() => {
      const pageConfig = { page: 1, offset: 8 };
      userService
        .getUserWithRole("", true, pageConfig, userData.value.email)
        .then((result) => {
          existingUsers.value = result.items;
          showSuggestion.value = true;
        });
    }, 500)();

    if (existingUsers.value) {
      updateInputFields();
    }
  }
}
const showSuggestion = ref(false);
const disabled = ref(false);
const durationDays = DurationDays;

// Validation states
const formErrors = ref({
  email: "",
  first_name: "",
  invitation_type: "",
  invited_for: "",
  rater_type: "",
  duration_days: "",
});

function validateForm() {
  let isValid = true;
  formErrors.value = {
    email: "",
    first_name: "",
    invitation_type: "",
    invited_for: "",
    rater_type: "",
    duration_days: "", // Add duration_days to formErrors
  };

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const nameRegex = /^[A-Za-z]+$/;

  if (!userData.value.email) {
    formErrors.value.email = "Email is required.";
    isValid = false;
  } else if (!emailRegex.test(userData.value.email)) {
    formErrors.value.email = "Email is not valid.";
    isValid = false;
  }

  if (!userData.value.first_name) {
    formErrors.value.first_name = "First name is required.";
    isValid = false;
  } else if (!nameRegex.test(userData.value.first_name)) {
    formErrors.value.first_name = "First name can only contain letters.";
    isValid = false;
  }

  if (!userData.value.invitation_type) {
    formErrors.value.invitation_type = "Please select a user type.";
    isValid = false;
  }

  if (
    userData.value.invitation_type === Role.Participants &&
    !userData.value.duration_days
  ) {
    formErrors.value.duration_days = "Please select the assessment duration.";
    isValid = false;
  }

  if (
    userData.value.invitation_type === Role.Participants &&
    !invitationFor.value
  ) {
    formErrors.value.invited_for = "Please select an admin.";
    isValid = false;
  }

  if (userData.value.invitation_type === Role.Raters && !invitationFor.value) {
    formErrors.value.invited_for = "Please select a participant.";
    isValid = false;
  }

  if (userData.value.invitation_type === Role.Raters && !raterTypeValue.value) {
    formErrors.value.rater_type = "Please select a rater type.";
    isValid = false;
  }

  return isValid;
}

function setUserInfo(user: UserInterface) {
  userData.value.email = user.email;
  userData.value.first_name = user.first_name;
  userData.value.last_name = user.last_name;
  showSuggestion.value = false;
  disabled.value = true;
}

function closeDropdown() {
  setTimeout(() => {
    if (userData.value.email.length != 0) {
      const user = existingUsers.value.find(
        (user) => user.email == userData.value.email,
      );
      if (user) {
        setUserInfo(user as UserInterface);
      } else {
        updateInputFields();
        showSuggestion.value = false;
      }
    }
  }, 200);
}

async function emitEdit() {
  if (!validateForm()) {
    return;
  }

  if (invitationFor.value != "") {
    userData.value.invited_for = Number(invitationFor.value);
  }
  if (raterTypeValue.value != "") {
    userData.value.rater_type = raterTypeValue.value;
  }

  if (
    userData.value.invitation_type === Role.Raters &&
    currentInvitationStore.currentInvitation?.invitation_type === Role.Admin
  ) {
    userData.value.admin_id = authService.user()!.id;
  }
  if (
    userData.value.invitation_type === Role.Raters &&
    currentInvitationStore.currentInvitation?.invitation_type ===
      Role.Participants
  ) {
    userData.value.admin_id =
      currentInvitationStore.currentInvitation.invited_for_user!.id;
  }
  if (userData.value.duration_days === 0) {
    userData.value.duration_days = null;
  }

  const loader = await loadingService.show();
  if (props.user.company_id) {
    companyService
      .editInvitation(userData.value, props.user.company_id, props.user.id)
      .then(() => {
        emit("edit");
        toastService.success("User Information Updated Successfully");
      })
      .catch((err) => {
        if (err.response.status === 403) {
          toastService.error("Something Went Wrong");
          authService.logout();
          return;
        }
        const error = err as AxiosError;
        const errorResponse = error.response?.data as ErrorResponse;

        if (errorResponse?.errors) {
          const flattenedErrors = Object.values(errorResponse.errors).flat();
          toastService.error(flattenedErrors.join("<br>"));
        } else {
          toastService.error("Unable to update user");
        }
      })
      .finally(() => {
        loader.hide();
      });
  } else {
    userService
      .editInvitation(userData.value, props.user.id)
      .then(() => {
        toastService.success("User Information Updated Successfully");
        emit("edit");
      })
      .catch((err) => {
        if (err.response.status === 403) {
          toastService.error("Something Went Wrong");
          authService.logout();
          return;
        }
        const error = err as AxiosError;
        const errorResponse = error.response?.data as ErrorResponse;

        if (errorResponse?.errors) {
          const flattenedErrors = Object.values(errorResponse.errors).flat();
          toastService.error(flattenedErrors.join("<br>"));
        } else {
          toastService.error("Unable to update user");
        }
        toastService.error("Unable to Update User");
      })
      .finally(() => {
        loader.hide();
      });
  }
}
</script>
<template>
  <div class="modal-backdrop">
    <div class="modal fade show" id="add-modal">
      <div
        class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
        v-if="!isLoading"
      >
        <div class="modal-content">
          <div class="modal-header">
            <p class="modal-title fw-bold">Edit User</p>
            <button
              type="button"
              class="btn-close"
              @click="emit('close')"
            ></button>
          </div>
          <ValidationErrorComponent
            v-if="validationErrors"
            :validations="validationErrors"
          ></ValidationErrorComponent>
          <form @submit.prevent="emitEdit" class="w-100 overflow-y-auto">
            <div class="modal-body">
              <div class="mb-3">
                <label class="form-label fw-bold">Email</label>
                <div class="position-relative">
                  <input
                    type="email"
                    maxlength="50"
                    placeholder="Email"
                    class="form-control email"
                    v-model="userData.email"
                    @input="fetchEmails"
                    @blur="closeDropdown"
                    data-cy=""
                  />
                  <div v-if="formErrors.email" class="text-danger mt-2 error">
                    {{ formErrors.email }}
                  </div>
                  <ul
                    class="position-absolute email-list list-unstyled"
                    v-show="showSuggestion"
                  >
                    <li
                      v-for="user in existingUsers"
                      :key="user.id"
                      :value="user.email"
                      @click="setUserInfo(user)"
                    >
                      {{ user.email }}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="mb-3">
                <label class="form-label fw-bold">First Name</label>
                <input
                  type="text"
                  placeholder="First Name"
                  class="form-control"
                  maxlength="14"
                  v-model="userData.first_name"
                  :disabled="!props.user.invited_user.is_editable"
                  data-cy=""
                />
                <div
                  v-if="formErrors.first_name"
                  class="text-danger mt-2 error"
                >
                  {{ formErrors.first_name }}
                </div>
              </div>
              <div class="mb-3">
                <label class="form-label fw-bold">Last Name</label>
                <input
                  type="text"
                  placeholder="Last Name"
                  class="form-control"
                  v-model.trim="userData.last_name"
                  maxlength="14"
                  :disabled="!props.user.invited_user.is_editable"
                  data-cy=""
                />
              </div>
              <div
                class="mb-3"
                v-if="userData.invitation_type === Role.Participants"
              >
                <label class="form-label fw-bold">Assessment Duration</label>
                <select class="form-select" v-model="userData.duration_days">
                  <option value="">Please Select Assessment Duration</option>
                  <option
                    v-for="(duration, index) in durationDays"
                    :key="index"
                    :value="duration"
                  >
                    {{ duration }}
                  </option>
                </select>
                <div
                  v-if="formErrors.duration_days"
                  class="text-danger mt-2 error"
                >
                  {{ formErrors.duration_days }}
                </div>
              </div>
              <template v-if="userData.invitation_type === Role.Raters">
                <div class="mb-3">
                  <label class="form-label fw-bold">Rater Type</label>
                  <select
                    class="form-select"
                    v-model="raterTypeValue"
                    :class="{ 'is-invalid': formErrors.rater_type }"
                    data-cy=""
                  >
                    <option value="">Please Select</option>
                    <template v-for="rater in RaterTypeNames" :key="rater">
                      <option :value="rater.value">
                        {{ rater.display_name }}
                      </option>
                    </template>
                  </select>
                  <div
                    v-if="formErrors.rater_type"
                    class="text-danger mt-2 error"
                  >
                    {{ formErrors.rater_type }}
                  </div>
                </div>
              </template>
            </div>
            <div class="modal-footer">
              <button
                type="submit"
                class="btn btn-primary btn-sm mx-auto text-white"
              >
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
select {
  text-transform: capitalize;
}

ul {
  max-height: 280px;
  width: 100%;
  overflow-y: auto;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  li {
    padding: 4px 6px;
    cursor: pointer;
    &:hover {
      background-color: #00000050;
    }
  }
}

.error {
  color: red;
  font-size: 0.875rem;
}

.is-invalid {
  border-color: red;
}
</style>
