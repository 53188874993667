<script setup lang="ts">
import { ref } from "vue";
import { CompanyService } from "../../../services/manageCompanyService";
import { appContainer } from "../../../container";
import { LoadingService } from "../../../services/loadingService";
import { ToastService } from "../../../services/toastService";
import { ErrorResponse } from "../../../interface/ErrorInterface";
import { AxiosError } from "axios";
import ValidationErrorComponent from "../../../components/ValidationErrorComponent.vue";
import { Roles } from "../../../interface/UserInterface";
import { ActiveRolesStore } from "../../../stores/ActiveRolesStore";
const companyService = appContainer.resolve(CompanyService);
const loadingService = appContainer.resolve(LoadingService);
const toastService = appContainer.resolve(ToastService);
const emit = defineEmits(["buy", "close"]);

const props = defineProps<{
  companyId: number;
}>();

const roleStore = ActiveRolesStore();
const participantCount = ref<number>();
const validationErrors = ref<ErrorResponse>();

const errors = ref<{
  participantCount?: string;
}>({});
// Function to validate the form
const validateForm = () => {
  errors.value = {};
  let isValid = true;

  if (!participantCount.value) {
    errors.value.participantCount = "Participant value is required";
    isValid = false;
  } else if (participantCount.value < 1) {
    errors.value.participantCount = "Participant count must be greater than 0 ";
    isValid = false;
  }
  return isValid;
};

async function purchaseFeedbacks() {
  if (validateForm()) {
    const loader = await loadingService.show();
    if (roleStore.activeRoles.includes(Roles.SuperAdmin)) {
      await companyService
        .purchaseFeedbacks(props.companyId, participantCount.value as number)
        .then(() => {
          toastService.success("Participants purchased successfully");
          emit("buy", participantCount.value);
        })
        .catch((err) => {
          const error = err as AxiosError;
          const errorResponse = error.response?.data as ErrorResponse;
          validationErrors.value = errorResponse;
        })
        .finally(() => {
          loader.hide();
        });
    } else {
      await companyService
        .getStripeUrl(props.companyId, participantCount.value as number)
        .then((result) => (window.location.href = result.url));
    }
  }
}
</script>

<template>
  <div class="modal-backdrop">
    <div class="modal" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <p class="text-center mt-4 px-2 fs-5">
              Enter the number of participants you want to purchase
            </p>
            <ValidationErrorComponent
              v-if="validationErrors"
              :validations="validationErrors"
            ></ValidationErrorComponent>
            <form
              @submit.prevent="purchaseFeedbacks"
              class="w-100 overflow-y-auto"
            >
              <div class="modal-body">
                <div class="mb-3">
                  <label class="form-label fw-bold">Purchase Participant</label>
                  <input
                    type="number"
                    placeholder="No. of participant"
                    class="form-control"
                    data-cy=""
                    v-model="participantCount"
                    min="1"
                    max="500"
                  />
                  <div
                    v-if="errors.participantCount"
                    class="text-danger error mt-2"
                  >
                    {{ errors.participantCount }}
                  </div>
                </div>
              </div>
              <div
                class="d-flex align-items-center justify-content-evenly p-4 gap-3 flex-wrap"
              >
                <button
                  type="button"
                  class="btn btn-danger btn-sm text-white"
                  @click="emit('close')"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  class="btn btn-secondary btn-sm text-white"
                  @click="purchaseFeedbacks"
                >
                  Purchase participant
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
button {
  width: calc(50% - 1.5rem);
}
@media (max-width: 767px) {
  button {
    width: 100%;
  }
}
</style>
