import { defineStore } from "pinia";
import { appContainer } from "../container";
import { StorageKeys, StorageService } from "../services/storage.service";
import { ref } from "vue";
import {
  InvitedUserInterface,
  UserFilterInterface,
  UserInterface,
} from "../interface/UserInterface";
import { UserService } from "../services/userService";
// import { RoleInterface } from "../interface/UserInterface";

export const useUserStore = defineStore("userStore", () => {
  const storageService = appContainer.resolve(StorageService);
  const userService = appContainer.resolve(UserService);

  const user = ref<UserInterface>();
  const invitation = ref<InvitedUserInterface | null>(null);
  const admin = ref<InvitedUserInterface | null>(null);

  async function getInvitation(
    userFilters: Partial<UserFilterInterface>,
    setTo: string,
    pageConfig: { page?: number; offset?: number } = {
      page: 1,
      offset: 8,
    },
  ) {
    await userService.getInvitedUser(userFilters, pageConfig).then((result) => {
      invitation.value = result.items[0];
      storageService.set(setTo as StorageKeys, invitation.value);
    });
  }

  async function getRater() {
    invitation.value = await storageService.get(StorageKeys.Rater);
  }
  // async function getAdmin() {
  //   admin.value = await storageService.get(StorageKeys.Rater);
  // }

  async function setAdmin(currentAdmin: InvitedUserInterface) {
    admin.value = currentAdmin;
  }

  async function setInvitation(newInvitation: InvitedUserInterface) {
    invitation.value = newInvitation;
  }

  async function getUser() {
    user.value = (await storageService.get(StorageKeys.USER)) as UserInterface;
  }

  async function setRater(newInvitation: InvitedUserInterface) {
    invitation.value = newInvitation;
    storageService.remove(StorageKeys.Participant);
    storageService.set(StorageKeys.Rater, invitation.value);
  }

  async function setParticipant(newInvitation: InvitedUserInterface) {
    invitation.value = newInvitation;
    storageService.remove(StorageKeys.Rater);

    storageService.set(StorageKeys.Participant, invitation.value);
  }

  return {
    getUser,
    user,
    getInvitation,
    invitation,
    setInvitation,
    getRater,
    setParticipant,
    setRater,
    setAdmin,
    admin,
  };
});
