<script lang="ts" setup>
import { onBeforeMount, ref } from "vue";
import {
  Role,
  InviteUserInterface,
  UserInterface,
  RaterTypeNames,
  DurationDays,
  InvitedUserInterface,
} from "../../../interface/UserInterface";
import { appContainer } from "../../../container";
import { UserService } from "../../../services/userService";
import { debounce } from "../../../utils/debounce";
import { AuthService } from "../../../services/authService";
import { ActiveRolesStore } from "../../../stores/ActiveRolesStore";
// import { CurrentInvitationStore } from "../../../stores/CurrentInvtationStore";
import { ErrorResponse } from "../../../interface/ErrorInterface";
import { LoadingService } from "../../../services/loadingService";
import { CompanyService } from "../../../services/manageCompanyService";
import { ToastService } from "../../../services/toastService";
import { AxiosError } from "axios";
import ValidationErrorComponent from "../../../components/ValidationErrorComponent.vue";
import { useUserStore } from "../../../stores/UserStore";

const emit = defineEmits(["close", "add"]);
const props = defineProps<{
  type: string;
  for?: number;
  currentInvitation?: InvitedUserInterface;
}>();

const userService = appContainer.resolve(UserService);
const authService = appContainer.resolve(AuthService);
const companyService = appContainer.resolve(CompanyService);

const loadingService = appContainer.resolve(LoadingService);
const toastService = appContainer.resolve(ToastService);

const userStore = useUserStore();
const userData = ref<InviteUserInterface>({
  first_name: "",
  last_name: "",
  email: "",
  invited_for: null,
  invitation_type: "",
  rater_type: null,
  duration_days: null,
  admin_id: null,
});

const invitationFor = ref("");
const raterTypeValue = ref("");
const durationDays = DurationDays;
const activeRoleStore = ActiveRolesStore();
// const currentInvitationStore = CurrentInvitationStore();
const adminId = ref<number>();

onBeforeMount(async () => {
  userData.value.invitation_type = props.type;
  userData.value.invited_for = props.for!;
  invitationFor.value = props.for!.toString();
  // await currentInvitationStore.getCurrentInvitation();

  if (activeRoleStore.activeRoles.includes("admin")) {
    adminId.value = userStore.user?.id;
  }
  if (activeRoleStore.activeRoles.includes("participant")) {
    adminId.value = props.currentInvitation?.invited_for_user?.id;
  }
});

async function emitAdd() {
  // Validate form fields
  if (!validateForm()) {
    return;
  }

  if (invitationFor.value != "") {
    userData.value.invited_for = Number(invitationFor.value);
  }
  if (raterTypeValue.value != "") {
    userData.value.rater_type = raterTypeValue.value;
  }
  if (
    userData.value.invitation_type === Role.Raters &&
    userStore.invitation?.invitation_type === Role.Admin
  ) {
    userData.value.admin_id = authService.user()!.id;
  }
  if (
    userData.value.invitation_type === Role.Raters &&
    userStore.invitation?.invitation_type === Role.Participants
  ) {
    userData.value.admin_id = userStore.invitation.invited_for_user!.id;
  }
  if (userData.value.duration_days === 0) {
    userData.value.duration_days = null;
  }

  const loader = await loadingService.show();
  if (userStore.invitation?.company_id) {
    //todo add company participant
    companyService
      .inviteEmployee(userData.value, userStore.invitation.company_id)
      .then(() => {
        toastService.success("User Added Successfully");

        emit("add");
      })
      .catch((err) => {
        if (err.response.status === 403) {
          toastService.error("Something Went Wrong");
          authService.logout();
          return;
        }
        const error = err as AxiosError;
        const errorResponse = error.response?.data as ErrorResponse;
        validationErrors.value = errorResponse;
      })
      .finally(() => loader.hide());
  } else {
    userService
      .addUser(userData.value)
      .then(() => {
        toastService.success("User Added Successfully");

        emit("add");
      })
      .catch((err) => {
        if (err.response.status === 403) {
          toastService.error("Something Went Wrong");
          authService.logout();
          return;
        }
        const error = err as AxiosError;
        const errorResponse = error.response?.data as ErrorResponse;
        validationErrors.value = errorResponse;
      })
      .finally(() => loader.hide());
  }
}

const existingUsers = ref<UserInterface[]>([]);

function updateInputFields() {
  disabled.value = false;
}

let previousController: AbortController | undefined;

const fetchEmails = debounce(function fetchEmails() {
  previousController?.abort();
  previousController = undefined;
  if (userData.value.email.length === 0) {
    existingUsers.value = [];
    updateInputFields();
    return;
  }

  if (userData.value.email.length >= 3) {
    const pageConfig = { page: 1, offset: 8 };
    previousController = new AbortController();
    userService
      .getUserWithRole(
        "",
        true,
        pageConfig,
        userData.value.email,
        previousController.signal,
      )
      .then((result) => {
        existingUsers.value = result.items;
        showSuggestion.value = true;
        if (existingUsers.value) {
          updateInputFields();
        }
      });
    if (existingUsers.value) {
      updateInputFields();
    }
  }
}, 500);
const showSuggestion = ref(false);
const disabled = ref(false);
const validationErrors = ref<ErrorResponse>();

// Validation states
const formErrors = ref({
  email: "",
  first_name: "",
  invitation_type: "",
  invited_for: "",
  rater_type: "",
  duration_days: "", // Add this field
});

function validateForm() {
  let isValid = true;
  formErrors.value = {
    email: "",
    first_name: "",
    invitation_type: "",
    invited_for: "",
    rater_type: "",
    duration_days: "", // Initialize this field
  };

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const nameRegex = /^[A-Za-z]+$/;

  if (!userData.value.email) {
    formErrors.value.email = "Email is required.";
    isValid = false;
  } else if (!emailRegex.test(userData.value.email)) {
    formErrors.value.email = "Email is not valid.";
    isValid = false;
  }

  if (!userData.value.first_name) {
    formErrors.value.first_name = "First name is required.";
    isValid = false;
  } else if (!nameRegex.test(userData.value.first_name)) {
    formErrors.value.first_name = "First name can only contain letters.";
    isValid = false;
  }

  if (!userData.value.invitation_type) {
    formErrors.value.invitation_type = "Please select a user type.";
    isValid = false;
  }

  if (
    userData.value.invitation_type === Role.Participants &&
    !invitationFor.value
  ) {
    formErrors.value.invited_for = "Please select an admin.";
    isValid = false;
  }

  if (userData.value.invitation_type === Role.Raters && !invitationFor.value) {
    formErrors.value.invited_for = "Please select a participant.";
    isValid = false;
  }

  if (userData.value.invitation_type === Role.Raters && !raterTypeValue.value) {
    formErrors.value.rater_type = "Please select a rater type.";
    isValid = false;
  }

  // Validate feedback duration if invitation type is Participants
  if (
    userData.value.invitation_type === Role.Participants &&
    !userData.value.duration_days
  ) {
    formErrors.value.duration_days = "Please select the assessment duration.";
    isValid = false;
  }

  return isValid;
}

function setUserInfo(user: UserInterface) {
  userData.value.email = user.email;
  userData.value.first_name = user.first_name;
  userData.value.last_name = user.last_name;
  showSuggestion.value = false;
  disabled.value = true;
}

function closeDropdown() {
  setTimeout(() => {
    if (userData.value.email.length != 0) {
      const user = existingUsers.value.find(
        (user) => user.email == userData.value.email,
      );
      if (user) {
        setUserInfo(user as UserInterface);
      } else {
        updateInputFields();
        showSuggestion.value = false;
      }
    }
  }, 200);
}
</script>

<template>
  <div class="modal-backdrop">
    <div class="modal fade show">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <p class="modal-title fw-bold">Add User</p>
            <button
              type="button"
              class="btn-close"
              @click="emit('close')"
            ></button>
          </div>
          <ValidationErrorComponent
            v-if="validationErrors"
            :validations="validationErrors"
          ></ValidationErrorComponent>
          <form @submit.prevent="emitAdd" class="w-100 overflow-y-auto">
            <div class="modal-body">
              <!-- Email Field -->
              <div class="mb-3">
                <label class="form-label fw-bold">Email</label>
                <div class="position-relative">
                  <input
                    type="email"
                    maxlength="50"
                    placeholder="Email"
                    class="form-control email"
                    v-model="userData.email"
                    @input="fetchEmails"
                    @blur="closeDropdown"
                    data-cy=""
                  />
                  <div v-if="formErrors.email" class="text-danger mt-2 error">
                    {{ formErrors.email }}
                  </div>
                  <ul
                    class="position-absolute email-list list-unstyled"
                    v-show="showSuggestion"
                  >
                    <li
                      v-for="user in existingUsers"
                      :key="user.id"
                      :value="user.email"
                      @click="setUserInfo(user)"
                    >
                      {{ user.email }}
                    </li>
                  </ul>
                </div>
              </div>

              <!-- First Name Field -->
              <div class="mb-3">
                <label class="form-label fw-bold">First Name</label>
                <input
                  type="text"
                  placeholder="First Name"
                  class="form-control"
                  maxlength="14"
                  v-model="userData.first_name"
                  :disabled="disabled"
                  data-cy=""
                />
                <div
                  v-if="formErrors.first_name"
                  class="text-danger mt-2 error"
                >
                  {{ formErrors.first_name }}
                </div>
              </div>

              <!-- Last Name Field -->
              <div class="mb-3">
                <label class="form-label fw-bold">Last Name</label>
                <input
                  type="text"
                  placeholder="Last Name"
                  class="form-control"
                  v-model.trim="userData.last_name"
                  maxlength="14"
                  :disabled="disabled"
                  data-cy=""
                />
              </div>

              <!-- Feedback Duration Field -->
              <div
                class="mb-3"
                v-if="userData.invitation_type === Role.Participants"
              >
                <label class="form-label fw-bold">Assessment Duration</label>
                <select
                  class="form-select"
                  v-model="userData.duration_days"
                  :class="{ 'is-invalid': formErrors.duration_days }"
                >
                  <option value="">Please Select Assessment Duration</option>
                  <option
                    v-for="(duration, index) in durationDays"
                    :key="index"
                    :value="duration"
                  >
                    {{ duration }}
                  </option>
                </select>
                <div
                  v-if="formErrors.duration_days"
                  class="text-danger mt-2 error"
                >
                  {{ formErrors.duration_days }}
                </div>
              </div>

              <!-- Rater Type Field -->
              <template v-if="userData.invitation_type === Role.Raters">
                <div class="mb-3">
                  <label class="form-label fw-bold">Rater Type</label>
                  <select
                    class="form-select"
                    v-model="raterTypeValue"
                    :class="{ 'is-invalid': formErrors.rater_type }"
                    data-cy=""
                  >
                    <option value="">Please Select</option>
                    <template v-for="rater in RaterTypeNames" :key="rater">
                      <option :value="rater.value">
                        {{ rater.display_name }}
                      </option>
                    </template>
                  </select>
                  <div
                    v-if="formErrors.rater_type"
                    class="text-danger mt-2 error"
                  >
                    {{ formErrors.rater_type }}
                  </div>
                </div>
              </template>
            </div>
            <div class="modal-footer">
              <button
                type="submit"
                class="btn btn-primary btn-sm mx-auto text-white"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
select {
  text-transform: capitalize;
}

ul {
  max-height: 280px;
  width: 100%;
  overflow-y: auto;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  li {
    padding: 4px 6px;
    cursor: pointer;
    &:hover {
      background-color: #00000050;
    }
  }
}

.is-invalid {
  border-color: red;
}
.text-danger {
  font-size: 0.875em;
}
</style>
