<script setup lang="ts">
import { onBeforeMount, ref } from "vue";
import router from "../../routes";
// import { CurrentInvitationStore } from "../../stores/CurrentInvtationStore";
import RaterQuestionComponent from "./RaterQuestionComponent.vue";
import {
  QuestionCategories,
  QuestionInputTypes,
  QuestionInterface,
  QuestionSubCategories,
} from "../../interface/QuestionInterface";
import { appContainer } from "../../container";
import { ManageQuestionService } from "../../services/manageQuestionService";
import { useUserStore } from "../../stores/UserStore";

// const currentInvitationStore = CurrentInvitationStore();
const userStore = useUserStore();

const questionService = appContainer.resolve(ManageQuestionService);

const ratingQuestion1 = ref<QuestionInterface>({
  id: 1,
  created_at: "2024-07-29 10:09:08",
  updated_at: "2024-07-29 10:09:09",
  question_text: "This is a sample rating type question",
  question_category: QuestionCategories.Lead,
  question_subcategory: QuestionSubCategories.SetDirection,
  input_type: QuestionInputTypes.Rating,
  company_id: null,
});

const paragraphQuestion1 = ref<QuestionInterface>({
  id: 1,
  created_at: "2024-07-29 10:09:10",
  updated_at: "2024-07-29 10:09:11",
  question_text: "This is a sample paragraph type question",
  question_category: null,
  question_subcategory: null,
  input_type: QuestionInputTypes.Paragraph,
  company_id: null,
});

const feedbackStatus = ref(false);
const isLoading = ref(true);

onBeforeMount(async () => {
  // currentInvitationStore.getCurrentInvitation();
  await getCurrentPage();
});

async function getCurrentPage() {
  questionService.getCurrentPage(userStore.invitation!.id).then((result) => {
    feedbackStatus.value = !(
      result.question.question_subcategory ===
      QuestionSubCategories.SetDirection
    );
    isLoading.value = false;
  });
}
</script>
<template>
  <div class="page-wrapper position-relative pb-5" v-if="!isLoading">
    <div class="d-flex justify-content-between align-items-center flex-wrap">
      <h2 class="fw-medium fs-4 m-0">Sample Questions</h2>
    </div>

    <div class="question-wrapper mt-4 mb-4">
      <p>
        Your thoughtful and honest responses will play a crucial role in this
        process. Please review the following details before moving towards
        sample questions:
      </p>
      <p class="mb-2 pb-4">
        Each screen has <strong>four questions</strong>. Please answer all four
        before moving to the next screen. At the end, you'll have the
        opportunity to provide <strong>open-ended feedback</strong>.
      </p>

      <h2 class="fw-medium fs-5 m-0">Rating Scale</h2>

      <div class="row gap-3 my-4 w-lg-75">
        <div class="col-lg-12">
          <div class="rate-btn-sample grey">0</div>
          - <strong>Unknown</strong> - (not factored into the scoring)
        </div>
        <div class="col-lg-11 ms-auto">
          <div class="rate-btn-sample">1</div>
          - <strong>Not at all</strong> - never demonstrated
        </div>
        <div class="col-lg-10 ms-auto">
          <div class="rate-btn-sample">2</div>
          - <strong>Slightly</strong> - rarely observed or minimal
        </div>

        <div class="col-lg-9 ms-auto">
          <div class="rate-btn-sample">3</div>
          - <strong>Somewhat</strong> - evident but not consistent
        </div>
        <div class="col-lg-8 ms-auto">
          <div class="rate-btn-sample">4</div>
          - <strong>Moderately</strong> - the behavior is present, but not fully
          developed
        </div>
        <div class="col-lg-7 ms-auto">
          <div class="rate-btn-sample">5</div>
          - <strong>Usually</strong> - consistently demonstrated in most
          situations
        </div>
        <div class="col-lg-6 ms-auto">
          <div class="rate-btn-sample">6</div>
          - <strong>Completely</strong> - exemplifies a high standard of
          excellence
        </div>
      </div>

      <p class="mb-4 fw-bold">Rating Type Questions</p>
      <RaterQuestionComponent
        :question="ratingQuestion1"
        :index="1"
        :preview="true"
      />
      <p class="fw-bold mb-0 mt-4">Written Comments</p>
      <RaterQuestionComponent
        :question="paragraphQuestion1"
        :index="1"
        :preview="true"
      />
    </div>
    <div class="d-flex justify-contetn-end">
      <button
        class="btn btn-secondary text-white ms-auto d-block start-btn"
        @click="router.push('/assessment/questions')"
      >
        {{ feedbackStatus ? "Continue" : "Start" }} Assessment
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../theme/variables";

.rating-scale {
  width: fit-content;
  button {
    --bs-btn-hover-color: #fff;
    --bs-btn-active-color: #fff;
    width: 80px;
  }
  gap: 16px;
}
.rate-btn {
  max-width: 170px;
  span {
    font-size: 13px;
  }
}
.text-area {
  max-width: 900px;
  textarea {
    height: 100px;
  }
}
.page-footer {
  bottom: 40px;
}
.rate-btn-sample {
  width: 40px;
  height: 40px;
  border: 1px solid var(--bs-primary);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--bs-primary);
  font-weight: 600;
  flex-shrink: 0;
  &.grey {
    color: #999;
    border-color: #999;
  }
}
.row > div {
  display: flex;
  align-items: center;
  gap: 10px;
}
@media (max-width: 575px) {
  .start-btn {
    padding: 6px;
    font-size: 14px;
  }
}
@media (min-width: 992px) {
  .w-lg-75 {
    width: 75%;
  }
}
</style>
