import { defineStore } from "pinia";
import { appContainer } from "../container";
import { StorageKeys, StorageService } from "../services/storage.service";
import { ref } from "vue";
// import { RoleInterface } from "../interface/UserInterface";

export const ActiveRolesStore = defineStore("activeRole", () => {
  const storageService = appContainer.resolve(StorageService);

  const activeRoles = ref<string[]>([]);

  async function getActiveRole() {
    activeRoles.value = (await storageService.get(
      StorageKeys.Active_Roles,
    )) as string[];
  }

  return {
    getActiveRole,
    activeRoles,
  };
});
