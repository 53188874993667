<script setup lang="ts">
import { onMounted, ref } from "vue";
import { appContainer } from "../../container";
import { AuthService } from "../../services/authService";
// import { CurrentInvitationStore } from "../../stores/CurrentInvtationStore";
import router from "../../routes";
// import { StorageService, StorageKeys } from "../../services/storage.service";
import { UserService } from "../../services/userService";
import { useUserStore } from "../../stores/UserStore";

const authService = appContainer.resolve(AuthService);
// const currentInvitationStore = CurrentInvitationStore();
const userStore = useUserStore();

const userService = appContainer.resolve(UserService);
// const storageService = appContainer.resolve(StorageService);

const showSwitchAccount = ref(false);
const isLoading = ref(true);

onMounted(() => {
  userStore.getRater();
  // currentInvitationStore.getCurrentInvitation();
  hasMultipleRoles();
});
async function hasMultipleRoles() {
  const totalRoles = authService.user()!.roles.length;
  const totalInvitations = await userService
    .getInvitedUser({
      allUsers: true,
      all: true,
      invited_user: authService.user()?.id,
    })
    .then((result) => {
      isLoading.value = false;
      console.log(isLoading.value);
      return result.items.filter((item) => item.invitation_sent === true)
        .length;
    });
  showSwitchAccount.value = totalRoles + totalInvitations > 2;
}

// function redirectToSwitchAccount() {
//   // storageService.remove(StorageKeys.Active_Roles);
//   // storageService.remove(StorageKeys.Current_Invitation);

// }
</script>

<template>
  <div
    v-if="!isLoading"
    class="d-flex align-items-center justify-content-center w-100 text-center px-3"
  >
    <div class="flex-column flex-center">
      <h1 class="ff-montserrat fw-semibold fs-6">
        Assessment Submitted
        <span class="text-secondary">Successfully!</span>
      </h1>
      <h3 class="ff-montserrat mb-4 text-body-tertiary">
        Thank you for submitting assessment for
        <strong>
          {{ userStore.invitation?.invited_for_user?.first_name }}
          {{ userStore.invitation?.invited_for_user?.last_name }}</strong
        >.
      </h3>
      <button
        class="btn btn-primary text-white mb-4"
        v-if="showSwitchAccount"
        @click="router.push('/dashboard')"
      >
        Dashboard
      </button>
      <button class="btn btn-danger text-white" @click="authService.logout()">
        Logout
      </button>
    </div>
  </div>
</template>

<style scoped lang="scss">
h3 {
  font-size: 14px;
}
button {
  padding: 5px 30px;
}
</style>
