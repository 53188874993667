<script lang="ts" setup>
import { onMounted, ref } from "vue";
import { appContainer } from "../container";
import { UserService } from "../services/userService";
import { AuthService } from "../services/authService";
import {
  InvitedUserInterface,
  Role,
  RoleInterface,
  UserInterface,
} from "../interface/UserInterface";
import PageHeaderComponent from "../components/PageHeaderComponent.vue";
import { StorageKeys, StorageService } from "../services/storage.service";
import router from "../routes";
// import { ToastService } from "../services/toastService";
import { LoadingService } from "../services/loadingService";
import { CurrentInvitationStore } from "../stores/CurrentInvtationStore";
import { ActiveRolesStore } from "../stores/ActiveRolesStore";
import { useIsFrozenStore } from "../stores/IsQuestionFrozenStore";
import SwitchAccountUserCard from "../components/SwitchAccountUserCard.vue";

const allInvitations = ref<InvitedUserInterface[]>();
const invitationForYear = ref<InvitedUserInterface[]>();
const userService = appContainer.resolve(UserService);
const authService = appContainer.resolve(AuthService);
const storageService = appContainer.resolve(StorageService);
// const toastService = appContainer.resolve(ToastService);
const loaderService = appContainer.resolve(LoadingService);
const currentInvitationStore = CurrentInvitationStore();
const activeRoleStore = ActiveRolesStore();

const currentInvitationId = ref<number | null>();
const activeRole = ref<RoleInterface>();

const currentInvitation = ref<InvitedUserInterface>();
const currentUser = ref<UserInterface | null>();
const currentYear = ref(new Date().getFullYear());
const allYear = ref<number[]>([]);
const isLoading = ref(true);
const superAdminId = ref<number>();

const frozenStatusStore = useIsFrozenStore();
onMounted(async () => {
  currentUser.value = authService.user();
  currentInvitationStore.getCurrentInvitation();
  await userService
    .getInvitedUser({
      invited_user: currentUser.value!.id,
      all: true,
      allUsers: true,
    })
    .then((result) => {
      isLoading.value = false;
      allInvitations.value = result.items;
      invitationForYear.value = result.items.filter(
        (item) =>
          Number(item.meta.year) === currentYear.value &&
          item.invitation_sent === true,
      );
    });
  setYearRange();
});

async function setCurrentInvitation() {
  await authService.fetchUser();
  const loader = await loaderService.show();
  // if (!currentInvitationId.value || !superAdminId.value) {
  //   toastService.error("Please Select an Invitation to Proceed");
  // }
  if (superAdminId.value) {
    await storageService.remove(StorageKeys.Current_Invitation);
    activeRole.value = authService
      .user()
      ?.roles.find((role) => role.role_name === "super_admin");
    await storageService.set(StorageKeys.Active_Roles, activeRole.value);
    frozenStatusStore.getQuestionStatus();
    router.push("/");
  } else {
    await userService
      .getCurrentInvitation(currentInvitationId.value as number)
      .then((result) => {
        currentInvitation.value = result;
        activeRole.value = authService
          .user()
          ?.roles.filter(
            (role) =>
              role.role_name === currentInvitation.value?.invitation_type,
          )[0];
      });
    await storageService.set(
      StorageKeys.Current_Invitation,
      currentInvitation.value,
    );
    await storageService.set(StorageKeys.Active_Roles, activeRole.value);

    currentInvitationStore.getCurrentInvitation();
    activeRoleStore.getActiveRole();
    if (currentInvitation.value?.invitation_type === Role.Raters) {
      router.push("/instructions");
    } else {
      router.push({ path: "/" });
    }
  }
  loader.hide();
}

async function setYearRange() {
  allInvitations.value?.forEach((invitation) =>
    allYear.value.push(Number(invitation.meta.year)),
  );
  allYear.value = [...new Set(allYear.value.sort((a, b) => b - a))];
}

function updateInvitationList() {
  invitationForYear.value = allInvitations.value?.filter(
    (item) => Number(item.meta.year) === currentYear.value,
  );
}
async function logout() {
  const loader = await loaderService.show();
  authService.logout().finally(() => loader.hide());
}
</script>
<template>
  <PageHeaderComponent
    v-if="router.currentRoute.value.fullPath === '/choose-roles'"
    :fullwidth="router.currentRoute.value.fullPath === '/choose-roles'"
  ></PageHeaderComponent>
  <div
    class="page-wrapper w-100"
    v-if="!isLoading"
    :class="{ 'p-0': router.currentRoute.value.fullPath === '/switch-roles' }"
  >
    <div
      class="accounts-card"
      :class="{
        ' mx-auto p-md-4':
          router.currentRoute.value.fullPath != '/switch-roles',
      }"
    >
      <div
        class="d-flex justify-content-between mb-3 flex-column-reverse gap-4"
      >
        <h1
          class="h4 m-0"
          :class="{
            'ff-montserrat  ':
              router.currentRoute.value.fullPath != '/switch-roles',
          }"
        >
          Choose Role
        </h1>
        <div class="btn-wrapper ms-auto">
          <button
            v-if="currentInvitationId || superAdminId"
            class="btn btn-primary text-white fw-medium ms-auto"
            @click="setCurrentInvitation"
          >
            Proceed
          </button>
          <button
            v-if="
              invitationForYear?.length &&
              router.currentRoute.value.fullPath != '/switch-roles'
            "
            class="btn btn-outline-danger fw-medium ms-3"
            @click="logout()"
          >
            Logout
          </button>
        </div>
      </div>

      <template v-if="router.currentRoute.value.fullPath != '/switch-roles'">
        <p>
          <strong
            >Hi, {{ currentUser?.first_name }}
            {{ currentUser?.last_name }}</strong
          >
        </p>
      </template>
      <div class="d-flex align-items-center gap-3 py-3">
        <div class="year" v-for="(year, index) in allYear" :key="index">
          {{ year }}
          <input
            type="radio"
            name="year"
            :value="year"
            v-model="currentYear"
            @change="updateInvitationList"
          />
        </div>
      </div>

      <p class="fs-6">
        You can login to any one of the following roles against your email:
        <strong class="break-word">{{ authService.user()?.email }}</strong>
      </p>

      <div class="user-listing ps-2">
        <template v-for="user in invitationForYear" :key="user.id">
          <SwitchAccountUserCard
            :user="user"
            @invitationSelected="(id: number) => (currentInvitationId = id)"
          />
        </template>

        <div
          class="user-card d-flex align-items-center gap-4 mb-3"
          v-if="
            authService
              .user()
              ?.roles.some(
                (item: RoleInterface) => item.role_name === 'super_admin',
              )
          "
        >
          <input
            type="radio"
            class="form-check-input"
            name="invitation"
            v-model="superAdminId"
            :value="authService.user()?.id"
            :checked="superAdminId === authService.user()?.id"
            :id="authService.user()!.id.toString()"
          />
          <label
            class="user-info d-flex align-items-center gap-3"
            :for="authService.user()!.id.toString()"
          >
            <div
              class="icon-wrapper d-flex align-items-center justify-content-center"
            >
              <i class="bi bi-person-fill-gear"></i>
            </div>
            <p class="mb-0 text-capitalize">Super Admin</p>
          </label>
        </div>
        <template v-if="!invitationForYear?.length">
          <p class="text-danger text-center">No Invitation for this Year</p>
        </template>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import "../theme/variables";
.page-wrapper {
  padding-top: 110px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.accounts-list {
  max-width: 500px;
  margin: 0 auto;
}
form {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  gap: 10px;
  button {
    width: fit-content;
    margin: 20px auto;
  }
}
.role-list {
  border: 1px solid rgba(74, 132, 221, 0.7);
  border-radius: 10px;
  padding: 0.2em 1em;
  position: relative;
  transition: ease 300ms;
  color: $blue;
  font-weight: 500;
  cursor: pointer;
  &.active {
    background-color: rgba(74, 132, 221);
    color: #fff;
  }
  input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    border-color: #848484 !important;
  }
  i {
    font-size: 20px;
    color: #fff;
    &::before {
      font-weight: 900 !important;
    }
  }
}

.accounts-card {
  max-width: 1000px;
  height: calc(100vh - 110px);
}
.user-listing {
  max-height: 530px;
  overflow: auto;
  height: calc(100vh - 332px);
}

.user-card {
  input {
    cursor: pointer;
  }
}
.user-info {
  cursor: pointer;
}

.year {
  position: relative;
  border-bottom: 4px solid transparent;

  input {
    appearance: none;
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
    cursor: pointer;
  }
  cursor: pointer;
  &:has(input:checked) {
    border-bottom: 4px solid $blue;
  }
}
.btn-outline-danger:hover {
  color: #fff !important;
}
small {
  font-size: 12px;
}

.icon-wrapper {
  background-color: #eaf1fb;
  color: $blue;
  padding: 10px;
  width: 64px;
  height: 64px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 24px;
  .bi-person-fill-gear {
    font-size: 30px;
  }
}
</style>
