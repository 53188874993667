<script lang="ts" setup>
import { onBeforeMount, ref } from "vue";
import { useRoute } from "vue-router";
import { appContainer } from "../../container";
import { UserService } from "../../services/userService";
import { LoadingService } from "../../services/loadingService";
import { InvitedUserInterface, Role } from "../../interface/UserInterface";
import { ActiveRolesStore } from "../../stores/ActiveRolesStore";
import UserCardListing from "../admin/UserCardListing.vue";
import AddRaterModal from "../admin/modal/AddUserModal.vue";
import { CurrentInvitationStore } from "../../stores/CurrentInvtationStore";
import DeleteUserModal from "./components/DeleteUserModal.vue";

const route = useRoute();
const userService = appContainer.resolve(UserService);
const loadingService = appContainer.resolve(LoadingService);
const participants = ref<InvitedUserInterface[]>([]);
const raters = ref<InvitedUserInterface[]>([]);

const currentInvitation = ref<InvitedUserInterface>();
const useActiveStore = ActiveRolesStore();
const showAddModal = ref(false);
const currentInvitationStore = CurrentInvitationStore();
const showDeleteModal = ref(false);
const toBeDeleted = ref<InvitedUserInterface>();

onBeforeMount(async () => {
  if (!useActiveStore.activeRoles.includes("super_admin")) {
    currentInvitationStore.getCurrentInvitation();
  }
  await getCurrentInvitation();
  if (currentInvitation.value?.invitation_type === Role.Admin) {
    userService
      .getInvitedUser({
        invited_for: currentInvitation.value.invited_user.id,
        invitationType: Role.Participants,
        all: true,
        ...(currentInvitation.value?.company_id
          ? {
              companyId: currentInvitation.value?.company_id,
            }
          : {}),
        year: currentInvitation.value.meta.year,
      })
      .then((result) => (participants.value = result.items));
  }
  if (currentInvitation.value?.invitation_type === Role.Participants) {
    getUpdatedRaterList();
  }
});

async function getCurrentInvitation() {
  const loader = await loadingService.show();
  return userService
    .getCurrentInvitation(Number(route.params.id))
    .then((result) => {
      currentInvitation.value = result;
    })
    .finally(() => loader.hide());
}

async function getUpdatedRaterList() {
  userService
    .getInvitedUser({
      invited_for: currentInvitation.value?.invited_user.id,
      invitationType: Role.Raters,
      all: true,
      ...(currentInvitation.value?.company_id
        ? {
            companyId: currentInvitation.value?.company_id,
          }
        : {}),
      year: currentInvitation.value?.meta.year,
      adminId: currentInvitation.value?.invited_for_user?.id,
    })
    .then((result) => (raters.value = result.items));
}

async function addUser() {
  showAddModal.value = false;
  getUpdatedRaterList();
}

function openDeleteModal(user: InvitedUserInterface) {
  toBeDeleted.value = user;
  showDeleteModal.value = true;
}

function emitDelete() {
  showDeleteModal.value = false;
  getUpdatedRaterList();
}
</script>
<template>
  <div class="breadcrumbs" v-if="!currentInvitationStore.currentInvitation">
    <router-link to="/manage-users" class="text-decoration-none"
      >Manage Users</router-link
    >
    / {{ currentInvitation?.invited_user.first_name }}
    {{ currentInvitation?.invited_user.last_name }}
  </div>
  <template
    v-if="currentInvitationStore.currentInvitation?.invitation_type === 'admin'"
  >
    <div class="breadcrumbs">
      <router-link to="/manage-participants" class="text-decoration-none"
        >Manage Participant</router-link
      >
      / {{ currentInvitation?.invited_user.first_name }}
      {{ currentInvitation?.invited_user.last_name }}
    </div>
  </template>
  <template
    v-if="
      currentInvitationStore.currentInvitation?.invitation_type ===
      'participant'
    "
  >
    <div class="breadcrumbs">
      <router-link to="/manage-raters" class="text-decoration-none"
        >Manage Rater</router-link
      >
      / {{ currentInvitation?.invited_user.first_name }}
      {{ currentInvitation?.invited_user.last_name }}
    </div>
  </template>
  <div class="invited-user-info p-4 mb-5 mt-4" v-if="currentInvitation">
    <p class="fw-medium name mb-0 text-capitalize">
      {{ currentInvitation.invited_user.first_name }}
      {{ currentInvitation.invited_user.last_name }}
    </p>
    <p class="email mb-2 break-word">
      {{ currentInvitation.invited_user.email }}
    </p>
    <span class="d-flex align-items-center role"
      >User Role:
      <strong class="text-capitalize ms-2">
        {{ currentInvitation.invitation_type }}</strong
      ></span
    >
  </div>
  <template v-if="currentInvitation?.invitation_type === Role.Admin">
    <h5 class="ff-montserrat fw-bold">Participant(s)</h5>
    <div class="user-list d-flex flex-column">
      <template v-for="user in participants" :key="user.id">
        <div class="user-card p-3 border-bottom">
          <p class="fw-medium name mb-0 text-capitalize">
            {{ user.invited_user.first_name }} {{ user.invited_user.last_name }}
          </p>
          <span class="email break-word">{{ user.invited_user.email }}</span>
          <div
            class="invite-tag"
            :class="user.invitation_sent ? 'text-secondary' : 'text-danger'"
          >
            {{ user.invitation_sent ? "Invited" : "Not Invited" }}
          </div>
        </div>
      </template>
    </div>
  </template>
  <template v-if="currentInvitation?.invitation_type === Role.Participants">
    <h5 class="ff-montserrat fw-bold">Admin</h5>
    <div class="user-list mb-5">
      <div class="user-card p-3 border-bottom">
        <p class="fw-medium name mb-0 text-capitalize">
          {{ currentInvitation.invited_for_user?.first_name }}
          {{ currentInvitation.invited_for_user?.last_name }}
        </p>
        <span class="email">{{
          currentInvitation.invited_for_user?.email
        }}</span>
      </div>
    </div>

    <template v-if="useActiveStore.activeRoles.includes(Role.Admin)">
      <div class="d-flex w-100 justify-content-between flex-wrap mb-4 gap-10">
        <h5 class="ff-montserrat fw-bold">Rater(s)</h5>
        <div class="btn-wrapper d-flex align-items-center flex-wrap">
          <button
            class="btn btn-primary btn-sm text-white"
            @click="showAddModal = true"
          >
            Add Rater
          </button>
        </div>
      </div>
      <div class="user-list mb-5">
        <UserCardListing
          v-for="rater in raters"
          :key="rater.id"
          :user="rater"
          :currentRole="Role.Raters"
          @invite="getUpdatedRaterList"
          @delete="getUpdatedRaterList"
          @edit="getUpdatedRaterList"
          :showDetail="false"
        />
      </div>
    </template>
    <template v-else>
      <h5 class="ff-montserrat fw-bold">Rater(s)</h5>
      <div class="user-list mb-5" v-if="raters.length">
        <template v-for="user in raters" :key="user.id">
          <div
            class="user-card p-3 border-bottom d-flex align-items-center justify-content-between"
          >
            <div>
              <p class="fw-medium name mb-0 text-capitalize">
                {{ user.invited_user.first_name }}
                {{ user.invited_user.last_name }}
              </p>
              <span class="email break-word">{{
                user.invited_user.email
              }}</span>
              <div
                class="invite-tag"
                :class="user.invitation_sent ? 'text-secondary' : 'text-danger'"
              >
                {{ user.invitation_sent ? "Invited" : "Not Invited" }}
              </div>
            </div>

            <button
              title="Delete Invitation"
              class="btn border-0 p-1"
              @click.stop="openDeleteModal(user)"
            >
              <i class="bi bi-trash3"></i>
            </button>
          </div>
        </template>
      </div>
      <div
        class="no-result d-flex align-items-center justify-content-center text-opacity-50 text-danger vh-50"
        v-else
      >
        No Records Found
      </div>
    </template>
  </template>

  <template v-if="currentInvitation?.invitation_type === Role.Raters">
    <h5 class="ff-montserrat fw-bold">Participant</h5>
    <div class="user-list mb-5">
      <div class="user-card p-3 border-bottom">
        <p class="fw-medium name mb-0 text-capitalize">
          {{ currentInvitation.invited_for_user?.first_name }}
          {{ currentInvitation.invited_for_user?.last_name }}
        </p>
        <span class="email">{{
          currentInvitation.invited_for_user?.email
        }}</span>
      </div>
    </div>
  </template>

  <AddRaterModal
    v-if="showAddModal"
    @add="addUser"
    @close="showAddModal = false"
    :type="Role.Raters"
    :for="currentInvitation?.invited_user.id"
  />
  <DeleteUserModal
    v-if="showDeleteModal"
    :invitation="toBeDeleted!"
    @close="showDeleteModal = false"
    @delete="emitDelete"
  />
</template>

<style lang="scss" scoped>
@import "../../theme/variables";

.invited-user-info {
  background: rgb($blue, 0.07);
  border-radius: 4px;
}
.email,
.role {
  font-size: 14px;
}

.user-list {
  gap: 10px;
}
.invite-tag {
  font-weight: 500;
  font-size: 12px;
}
</style>
