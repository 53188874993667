import axios from "axios";
import { inject, injectable } from "inversify";
import { ApiCallService } from "./apiCall.service";
import { AuthService } from "./authService";
import {
  PdfResources,
  PdfResourceUpload,
} from "../interface/ResourcesInterface";
import { PagingInterface } from "../interface/PaginationInterface";

@injectable()
export class ResourceService {
  constructor(
    @inject(ApiCallService) public apiCallService: ApiCallService,
    @inject(AuthService) public authService: AuthService,
  ) {}

  public async addResource(
    formData: PdfResourceUpload,
  ): Promise<PdfResourceUpload> {
    return axios
      .post<PdfResourceUpload>(
        this.apiCallService.apiUrl(`pdf-resources`),
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      )
      .then((result) => result.data);
  }

  public async fetchResources(
    pageConfig: { page?: number; offset?: number } = {
      page: 1,
      offset: 8,
    },
  ): Promise<PagingInterface<PdfResources>> {
    return axios
      .get<PagingInterface<PdfResources>>(
        this.apiCallService.apiUrl("pdf-resources"),
        {
          params: {
            ...pageConfig,
          },
        },
      )
      .then((result) => result.data);
  }

  public async deleteResource(
    resourcesId: number,
  ): Promise<{ status: string }> {
    return axios
      .delete<{
        status: string;
      }>(this.apiCallService.apiUrl(`pdf-resources/${resourcesId}`))
      .then((result) => result.data);
  }
}
