<script setup lang="ts">
import { onMounted, ref } from "vue";
import ModalAddCompany from "./components/ModalAddCompany.vue";
import ListingCompany from "./components/ListingCompany.vue";
import { CompanyInterface } from "../../interface/CompanyInterface";
import { appContainer } from "../../container";
import { CompanyService } from "../../services/manageCompanyService";
import { LoadingService } from "../../services/loadingService";
import { ActiveRolesStore } from "../../stores/ActiveRolesStore";
import { useUserStore } from "../../stores/UserStore";
import { Role, Roles } from "../../interface/UserInterface";
import { UserService } from "../../services/userService";
import { ToastService } from "../../services/toastService";

// Define Modal Show/Hide
const showModal = ref(false);
const isCertified = ref();
const isLoading = ref(true);
const closeModal = () => {
  showModal.value = false;
};

const toastService = appContainer.resolve(ToastService);

const companyService = appContainer.resolve(CompanyService);
const userService = appContainer.resolve(UserService);
const loadingService = appContainer.resolve(LoadingService);

const activeRolesStore = ActiveRolesStore();
const userStore = useUserStore();

// Define a ref to store received company data as an array
const receivedCompanyData = ref<CompanyInterface[]>([]);

onMounted(async () => {
  userStore.getUser();
  await getCompanies();
  isCertified.value = userStore.user?.certification;
});

const openModal = () => {
  if (
    isCertified.value ||
    activeRolesStore.activeRoles.includes("super_admin") ||
    receivedCompanyData.value.length < 1
  ) {
    showModal.value = true;
  } else {
    toastService.error("Get Certified to add more Companies");
  }
};

// Handle the companyAdded event
const handleCompanyAdded = async () => {
  closeModal();
  await getCompanies();
};

async function getCompanies() {
  const loader = await loadingService.show();

  if (activeRolesStore.activeRoles.includes("super_admin")) {
    companyService
      .fetchCompanies()
      .then((result) => {
        receivedCompanyData.value = result.items;
      })
      .finally(() => {
        loader.hide();
        isLoading.value = false;
      });
  } else if (activeRolesStore.activeRoles.includes(Role.Admin)) {
    userService
      .getInvitedUser({
        invited_user: userStore.user?.id,
        invitationType: Role.Admin,
        invitation_sent: true,
        allUsers: true,
      })
      .then((result) => {
        receivedCompanyData.value = [];
        result.items.map((item) => {
          if (item.company)
            receivedCompanyData.value.push(item.company as CompanyInterface);
        });
        console.log(result, receivedCompanyData.value);
      })
      .finally(() => {
        loader.hide();
        isLoading.value = false;
      });
  }
}
</script>

<template>
  <ModalAddCompany
    v-if="showModal"
    @close="closeModal"
    @companyAdded="handleCompanyAdded"
  />
  <div class="wrap-company-view">
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h2 class="fw-medium fs-4 p-0">Manage Companies</h2>
      <a
        href="https://trustedleader360.com/purchase/"
        v-if="
          !isCertified &&
          !activeRolesStore.activeRoles.includes(Roles.SuperAdmin)
        "
        class="btn btn-secondary btn-sm text-white ms-auto fw-semibold"
        target="_blank"
      >
        Get Certified
      </a>
      <button class="btn btn-primary btn-sm text-white ms-3" @click="openModal">
        Add Company
      </button>
    </div>
    <div>
      <ListingCompany
        v-if="receivedCompanyData.length"
        @edit="getCompanies()"
        @copy="getCompanies()"
        @companyDeleted="getCompanies()"
        :receivedCompanyData="receivedCompanyData"
      />
      <div
        class="no-result d-flex align-items-center justify-content-center text-opacity-50 text-danger"
        v-else-if="!isLoading"
      >
        No Records Found
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
