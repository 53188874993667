<script setup lang="ts">
import { ref } from "vue";
import { ErrorResponse } from "../../interface/ErrorInterface";
import ValidationErrorComponent from "../../components/ValidationErrorComponent.vue";
import { PdfResourceUpload } from "../../interface/ResourcesInterface";
import { ResourceService } from "../../services/resourceService";

import { LoadingService } from "../../services/loadingService";
import { ToastService } from "../../services/toastService";
import { appContainer } from "../../container";
import { AxiosError } from "axios";

const validationErrors = ref<ErrorResponse>();

const resourceService = appContainer.resolve(ResourceService);
const loadingService = appContainer.resolve(LoadingService);
const toastService = appContainer.resolve(ToastService);

const selectedPdf = ref<PdfResourceUpload | null>(null);
const fileName = ref("");
const selectedFile = ref<File | null>(null);

const errors = ref<{
  name?: string;
  file?: string;
}>({});

const handleFileUpload = (event: Event) => {
  const target = event.target as HTMLInputElement;
  if (target.files && target.files[0]) {
    const file = target.files?.[0];

    selectedFile.value = file;
    selectedPdf.value = {
      name: fileName.value.trim(),
      file: selectedFile.value,
      original_name: file.name,
    };
  }
};

const addResources = async () => {
  if (validateForm()) {
    const loader = await loadingService.show();
    console.log(selectedPdf.value);
    resourceService
      .addResource(selectedPdf.value as PdfResourceUpload)
      .then(() => {
        toastService.success("Resource Added Successfully");
        emit("fileUploaded");
      })
      .catch((err) => {
        const error = err as AxiosError;
        const errorResponse = error.response?.data as ErrorResponse;

        if (errorResponse?.errors) {
          const flattenedErrors = Object.values(errorResponse.errors).flat();
          toastService.error(flattenedErrors.join("<br>"));
        }
      })
      .finally(() => loader.hide());
  }
};

const validateForm = () => {
  errors.value = {};
  let isValid = true;

  if (!fileName.value) {
    errors.value.name = "File Name is required.";
    isValid = false;
  }

  if (!selectedPdf.value || !selectedPdf.value.file) {
    errors.value.file = "Please upload a PDF file.";
    isValid = false;
  }
  return isValid;
};

const emit = defineEmits(["close", "fileUploaded"]);
</script>

<template>
  <div class="modal-backdrop">
    <div class="modal fade show" id="edit-modal">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <p class="modal-title fw-bold">Add Resource</p>
            <button
              type="button"
              class="btn-close"
              @click="emit('close')"
            ></button>
          </div>
          <ValidationErrorComponent
            v-if="validationErrors"
            :validations="validationErrors"
          ></ValidationErrorComponent>
          <form class="w-100 overflow-y-auto" @submit.prevent="addResources">
            <div class="modal-body">
              <div class="mb-3">
                <label for="resourceName" class="fw-bold mb-2 mt-3"
                  >File Name</label
                >
                <input
                  type="text"
                  id="resourceName"
                  v-model="fileName"
                  placeholder="Name"
                  maxlength="80"
                  class="form-control"
                />
                <div v-if="errors.name" class="text-danger error mt-2">
                  {{ errors.name }}
                </div>
              </div>
              <div class="mb-3">
                <label for="resource" class="fw-bold mb-2 mt-3"
                  >Upload Resource</label
                >
                <input
                  type="file"
                  id="resource"
                  class="form-control"
                  accept=".pdf"
                  @change="handleFileUpload"
                />
                <div v-if="errors.file" class="text-danger error mt-2">
                  {{ errors.file }}
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="submit"
                class="btn btn-primary btn-sm mx-auto text-white"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
