<script setup lang="ts">
import { ref } from "vue";
import { CompanyInterface } from "../../../interface/CompanyInterface";
import { CompanyService } from "../../../services/manageCompanyService";
import { appContainer } from "../../../container";
import { ErrorResponse } from "../../../interface/ErrorInterface";
import { LoadingService } from "../../../services/loadingService";
import { ToastService } from "../../../services/toastService";
import { AxiosError } from "axios";
import ValidationErrorComponent from "../../../components/ValidationErrorComponent.vue";

const props = defineProps<{
  company: CompanyInterface | null;
}>();

const companyName = ref<string>("");
const formErrors = ref<{ [key: string]: string }>({});
const emit = defineEmits(["close", "copy"]);
const validationErrors = ref<ErrorResponse>();

const companyService = appContainer.resolve(CompanyService);
const loadingService = appContainer.resolve(LoadingService);
const toastService = appContainer.resolve(ToastService);

function validateForm() {
  formErrors.value = {};
  if (!companyName.value.trim()) {
    formErrors.value.companyName = "Company name is required.";
  }
}

async function emitCopy() {
  validateForm();
  if (Object.keys(formErrors.value).length === 0) {
    const loader = await loadingService.show();
    companyService
      .createCopy(props.company!.id, companyName.value)
      .then(() => {
        toastService.success("Company Copied Successfully");
        emit("copy");
      })
      .catch((err) => {
        const error = err as AxiosError;
        const errorResponse = error.response?.data as ErrorResponse;
        validationErrors.value = errorResponse;
      })
      .finally(() => loader.hide());
  }
}
</script>

<template>
  <div class="modal-backdrop">
    <div class="modal fade show" id="modal-copy-company">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <ValidationErrorComponent
            v-if="validationErrors"
            :validations="validationErrors"
          ></ValidationErrorComponent>
          <form class="w-100 overflow-y-auto" @submit.prevent="">
            <div class="modal-body pt-5">
              <h5 class="ff-montserrat text-center m-0">
                Would you like to make a copy?
              </h5>
              <div class="my-3">
                <label class="form-label fw-bold">Company Name</label>
                <input
                  type="text"
                  placeholder="Name"
                  class="form-control"
                  v-model="companyName"
                />
                <div v-if="formErrors.companyName" class="text-danger mt-1">
                  {{ formErrors.companyName }}
                </div>
              </div>
            </div>
            <div
              class="modal-footer border-0 d-flex justify-content-center pt-2 pb-5"
            >
              <button
                type="button"
                class="btn btn-outline-danger mx-3 btn-sm"
                @click="emit('close')"
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-secondary btn-sm text-white mx-3"
                @click="emitCopy"
              >
                Create Copy
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.modal-body {
  h5 {
    line-height: 1.4;
  }
}
.btn-outline-primary:hover {
  color: white !important;
}
.text-danger {
  font-size: 0.875rem;
}
.btn-outline-danger:hover {
  color: #fff;
}
</style>
