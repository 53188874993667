<script setup lang="ts">
import { ref, onMounted } from "vue";
import ModalAddFiles from "../manage-resources/AddFilesModal.vue";
import { PdfResources } from "../../interface/ResourcesInterface";
import { PagingInterface } from "../../interface/PaginationInterface";
import { appContainer } from "../../container";
import { ResourceService } from "../../services/resourceService";
import { LoadingService } from "../../services/loadingService";
import PaginationComponent from "../../components/PaginationComponent.vue";
import ResourceListingCard from "./ResourceListingCard.vue";
import { ActiveRolesStore } from "../../stores/ActiveRolesStore";
import { Roles } from "../../interface/UserInterface";

const resourceService = appContainer.resolve(ResourceService);
const loadingService = appContainer.resolve(LoadingService);
const paginatedData = ref<PagingInterface<PdfResources>>();
const activeRoleStore = ActiveRolesStore();

const resourceData = ref<PdfResources[]>([]);
const showModal = ref(false);
const isLoading = ref(true);

const openModal = () => {
  showModal.value = true;
};

const closeModal = () => {
  fetchResources();
  showModal.value = false;
};

const handleFileUploaded = () => {
  closeModal();
};

const fetchResources = async (pageConfig = { page: 1, offset: 8 }) => {
  const loader = await loadingService.show();
  isLoading.value = true;
  return resourceService
    .fetchResources(pageConfig)
    .then((result) => {
      paginatedData.value = result;
      resourceData.value = result.items || [];
    })
    .catch((error) => {
      console.error("Error fetching resources:", error);
    })
    .finally(() => {
      loader.hide();
      isLoading.value = false;
    });
};

function updatePage(pageNumber: number) {
  if (pageNumber === paginatedData.value?.page) {
    return;
  }
  fetchResources({ page: pageNumber, offset: 8 });
}

function visibleTo(roles: string[]) {
  return activeRoleStore.activeRoles?.some((item) => roles.includes(item));
}

onMounted(() => {
  fetchResources();
});
</script>

<template>
  <ModalAddFiles
    v-if="showModal"
    @close="closeModal"
    @fileUploaded="handleFileUploaded"
  />

  <div class="d-flex justify-content-between align-items-center mb-3">
    <h2 class="fw-medium fs-4 p-0">Manage Resources</h2>
    <button
      v-if="visibleTo([Roles.SuperAdmin])"
      class="btn btn-primary btn-sm text-white ms-3"
      @click="openModal"
    >
      Add Resource
    </button>
  </div>

  <div v-if="resourceData.length">
    <ResourceListingCard
      v-for="resource in resourceData"
      :key="resource.id"
      :resource="resource"
      @ResourceDeleted="fetchResources()"
    />
  </div>
  <div
    v-else-if="!isLoading"
    class="no-result d-flex align-items-center justify-content-center text-opacity-50 text-danger py-4"
  >
    No Resources Found.
  </div>
  <div class="pagination-wrapper" v-if="paginatedData?.totalItems">
    <PaginationComponent
      v-if="paginatedData.totalItems > 8"
      :totalRows="paginatedData!.totalItems"
      :currentPage="paginatedData?.page"
      :perPage="paginatedData?.offset"
      @pageChange="updatePage"
    />
  </div>
</template>

<style lang="scss">
@import "../../theme/variables";

.img-resource {
  padding: 5px;
  background-color: #eaf1fb;
  color: $blue;
  width: 64px;
  height: 64px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.group-btns {
  display: flex;
  align-items: center;

  button {
    font-size: 18px;
    color: #000;
  }
}
</style>
