import { InvitedUserInterface } from "../interface/UserInterface";

export function getDaysLeft(invitation: InvitedUserInterface) {
  const today = new Date();
  const completionDate = new Date(invitation!.meta.feedback_completion_date);

  return Math.round(
    (Date.parse(completionDate.toISOString()) -
      Date.parse(today.toISOString())) /
      1000 /
      60 /
      60 /
      24,
  );
}
