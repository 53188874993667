import { inject, injectable } from "inversify";
import { ApiCallService } from "./apiCall.service";
import axios from "axios";
import { AuthService } from "./authService";
import { PagingInterface } from "../interface/PaginationInterface";
import {
  CompanyEmployeeInterface,
  CompanyInterface,
  EmployeeInterface,
} from "../interface/CompanyInterface";
import {
  QuestionInfo,
  QuestionInterface,
} from "../interface/QuestionInterface";
import {
  InvitedUserInterface,
  InviteUserInterface,
} from "../interface/UserInterface";

@injectable()
export class CompanyService {
  constructor(
    @inject(ApiCallService) public apiCallService: ApiCallService,
    @inject(AuthService) public authService: AuthService,
  ) {}

  //public async addCompany(payload: CompanyInterface) {}

  public async addCompany(
    payload: CompanyInterface,
    logo: File,
  ): Promise<CompanyInterface> {
    return axios
      .post<CompanyInterface>(this.apiCallService.apiUrl("companies"), {
        ...payload,
      })
      .then((result) =>
        logo ? this.uploadLogo(result.data.id, logo) : result.data,
      );
  }

  public async uploadLogo(id: number, file: File): Promise<CompanyInterface> {
    const formData = new FormData();
    formData.append("file", file);
    return axios
      .post<CompanyInterface>(
        this.apiCallService.apiUrl(`companies/${id}/logo`),
        formData,
      )
      .then((result) => result.data);
  }

  public async fetchCompanies(): Promise<PagingInterface<CompanyInterface>> {
    return axios
      .get<PagingInterface<CompanyInterface>>(
        this.apiCallService.apiUrl("companies"),
        {
          params: {
            all: true,
          },
        },
      )
      .then((result) => result.data);
  }

  public async deleteCompany(id: number) {
    return axios.delete<{ status: string }>(
      this.apiCallService.apiUrl(`companies/${id}`),
    );
  }

  public async updateCompany(
    updatedCompany: CompanyInterface,
    logo: File,
    id: number,
  ): Promise<CompanyInterface> {
    return axios
      .put<CompanyInterface>(
        this.apiCallService.apiUrl(`companies/${id}`),
        updatedCompany,
      )
      .then((result) =>
        logo ? this.uploadLogo(result.data.id, logo) : result.data,
      );
  }

  public async createCopy(id: number, name: string): Promise<CompanyInterface> {
    return axios
      .post<CompanyInterface>(
        this.apiCallService.apiUrl(`companies/${id}/copy`),
        {
          name: name,
        },
      )
      .then((result) => result.data);
  }
  public async addCompanyQuestion(
    id: number,
    question: { question_text: string },
  ): Promise<QuestionInterface> {
    return axios
      .post<QuestionInterface>(
        this.apiCallService.apiUrl(`companies/${id}/questions`),
        question,
      )
      .then((result) => result.data);
  }

  public async getCompanyQuestion(
    pageConfig: { page?: number; offset?: number } = {
      page: 1,
      offset: 8,
    },
    companyId: number,
  ): Promise<PagingInterface<QuestionInterface>> {
    return axios
      .get<PagingInterface<QuestionInterface>>(
        this.apiCallService.apiUrl(`questions`),
        {
          params: {
            ...pageConfig,
            companyId,
          },
        },
      )
      .then((result) => result.data);
  }

  public async deleteCompanyQuestion(
    questionId: number,
    companyId: number,
  ): Promise<{ status: string }> {
    return axios
      .delete<{
        status: string;
      }>(
        this.apiCallService.apiUrl(
          `companies/${companyId}/questions/${questionId}`,
        ),
      )
      .then((result) => result.data);
  }

  public async editCompanyQuestion(
    question: QuestionInterface,
  ): Promise<QuestionInterface> {
    return axios
      .put<QuestionInterface>(
        this.apiCallService.apiUrl(
          `companies/${question.company_id}/questions/${question.id}`,
        ),
        question,
      )
      .then((result) => result.data);
  }

  public async freezeCompanyQuestions(
    id: number,
    freeze: boolean,
  ): Promise<QuestionInfo> {
    return axios
      .put<QuestionInfo>(
        this.apiCallService.apiUrl(`companies/${id}/questions/freeze`),
        {
          freeze,
        },
      )
      .then((result) => result.data);
  }

  public async updateEmployee(
    employeeData: EmployeeInterface,
    empId: number,
    companyId: number,
  ): Promise<CompanyEmployeeInterface> {
    return axios
      .put<CompanyEmployeeInterface>(
        this.apiCallService.apiUrl(`companies/${companyId}/employees/${empId}`),
        employeeData,
      )
      .then((result) => result.data);
  }

  public async inviteEmployee(
    data: InviteUserInterface,
    id: number,
  ): Promise<InvitedUserInterface> {
    return axios
      .post<InvitedUserInterface>(
        this.apiCallService.apiUrl(`companies/${id}/invitations`),
        data,
      )
      .then((result) => result.data);
  }
  public async sendInvitation(
    companyId: number,
    invitationId: number,
  ): Promise<{ status: string }> {
    return axios
      .post<{
        status: string;
      }>(
        this.apiCallService.apiUrl(
          `companies/${companyId}/invitations/${invitationId}/send`,
        ),
      )
      .then((result) => result.data);
  }
  public async editInvitation(
    editData: InviteUserInterface,
    companyId: number,
    invitationId: number,
  ): Promise<InvitedUserInterface> {
    return axios
      .put<InvitedUserInterface>(
        this.apiCallService.apiUrl(
          `companies/${companyId}/invitations/${invitationId}`,
        ),
        editData,
      )
      .then((result) => result.data);
  }

  public async getCompanyQuestionStatus(id: number): Promise<QuestionInfo> {
    return axios
      .get<QuestionInfo>(
        this.apiCallService.apiUrl(`companies/${id}/questions/frozen-status`),
      )
      .then((result) => result.data);
  }

  public async deleteCompanyInvitation(
    id: number,
    companyId: number,
  ): Promise<{ status: string }> {
    return axios
      .delete<{
        status: string;
      }>(this.apiCallService.apiUrl(`companies/${companyId}/invitations/${id}`))
      .then((result) => result.data);
  }

  public async purchaseFeedbacks(companyId: number, participantCount: number) {
    return axios
      .put<{
        allowed_feedbacks: number;
      }>(
        this.apiCallService.apiUrl(`companies/${companyId}/purchase-feedbacks`),
        { allowed_feedbacks: participantCount },
      )
      .then((result) => result.data);
  }

  public async getStripeUrl(
    id: number,
    feedbackCount: number,
  ): Promise<{ url: string }> {
    return axios
      .get<{ url: string }>(this.apiCallService.apiUrl(`purchases/feedbacks`), {
        params: {
          companyId: id,
          numberOfFeedbacks: feedbackCount,
        },
      })
      .then((result) => result.data);
  }
}
