<script lang="ts" setup>
import { onMounted, ref, computed } from "vue";
import { appContainer } from "../container";
import { AuthService } from "../services/authService";
import { UserService } from "../services/userService";
import { LoadingService } from "../services/loadingService";
import { ToastService } from "../services/toastService";
import router from "../routes";
import { ActiveRolesStore } from "../stores/ActiveRolesStore";
import { AxiosError } from "axios";
import { ErrorResponse } from "../interface/ErrorInterface";

const authService = appContainer.resolve(AuthService);
const userService = appContainer.resolve(UserService);
const loadingService = appContainer.resolve(LoadingService);
const toastService = appContainer.resolve(ToastService);
const currentUser = ref();
const updatedData = ref({
  first_name: "",
  last_name: "",
  email: "",
});

const activeRoleStore = ActiveRolesStore();

const oldPassword = ref("");
const newPassword = ref("");
const showPassword = ref(false);
const showConfirmPassword = ref(false);

// Validation states
const profileErrors = ref({
  first_name: "",
  last_name: "",
  email: "",
});

const passwordErrors = ref({
  oldPassword: "",
  newPassword: "",
});

currentUser.value = authService.user();
onMounted(() => {
  updatedData.value.email = currentUser.value.email;
  updatedData.value.first_name = currentUser.value.first_name;
  updatedData.value.last_name = currentUser.value.last_name;
});

function validateProfileForm() {
  let isValid = true;
  profileErrors.value = {
    first_name: "",
    last_name: "",
    email: "",
  };

  const nameRegex = /^[A-Za-z]+$/;

  if (!updatedData.value.first_name) {
    profileErrors.value.first_name = "First name is required.";
    isValid = false;
  } else if (!nameRegex.test(updatedData.value.first_name)) {
    profileErrors.value.first_name = "First name can only contain letters.";
    isValid = false;
  }

  if (!updatedData.value.last_name) {
    profileErrors.value.last_name = "Last name is required.";
    isValid = false;
  } else if (!nameRegex.test(updatedData.value.last_name)) {
    profileErrors.value.last_name = "Last name can only contain letters.";
    isValid = false;
  }

  return isValid;
}

async function updateProfile() {
  if (!validateProfileForm()) {
    return;
  }

  const loader = await loadingService.show();

  userService
    .updateProfile(updatedData.value)
    .then(() => {
      toastService.success("Profile Information Updated Successfully");
      router.push("/dashboard");
    })
    .catch(() => {
      toastService.error("Unable to update profile");
    })
    .finally(() => loader.hide());
}

function validatePasswordForm() {
  let isValid = true;
  passwordErrors.value = {
    oldPassword: "",
    newPassword: "",
  };

  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#^]{8,}$/;

  if (!oldPassword.value) {
    passwordErrors.value.oldPassword = "Current password is required.";
    isValid = false;
  }
  if (!newPassword.value) {
    passwordErrors.value.newPassword = "New password is required.";
    isValid = false;
  } else if (!passwordRegex.test(newPassword.value)) {
    passwordErrors.value.newPassword =
      "Password does not meet the requirements.";
    isValid = false;
  }
  return isValid;
}

async function updatePassword() {
  if (!validatePasswordForm()) {
    return;
  }

  const loader = await loadingService.show();

  userService
    .updatePassword(oldPassword.value, newPassword.value)
    .then(() => {
      toastService.success("Password Updated Successfully");
      authService.logout();
    })
    .catch((err) => {
      const error = err as AxiosError;
      const errorResponse = error.response?.data as ErrorResponse;
      for (const errorKey in errorResponse.errors) {
        toastService.error(`${errorResponse.errors[errorKey]}`);
      }
    })
    .finally(() => loader.hide());
}

// Password requirement validation
const passwordRequirements = computed(() => ({
  hasLowerCase: /[a-z]/.test(newPassword.value),
  hasUpperCase: /[A-Z]/.test(newPassword.value),
  hasNumber: /\d/.test(newPassword.value),
  hasSpecialChar: /[@$!%*?&#^]/.test(newPassword.value),
  hasMinLength: newPassword.value.length >= 8,
}));
</script>
<template>
  <ul
    class="nav nav-pills mb-5 justify-content-between justify-content-sm-start"
    id="pills-tab"
    role="tablist"
  >
    <li class="nav-item" role="presentation">
      <button
        class="nav-link active"
        id="pills-basic-info-tab"
        data-bs-toggle="pill"
        data-bs-target="#basic-info"
        type="button"
        role="tab"
        aria-controls="pills-basic-info"
        aria-selected="true"
      >
        Basic Information
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button
        class="nav-link"
        id="pills-update-password-tab"
        data-bs-toggle="pill"
        data-bs-target="#update-password"
        type="button"
        role="tab"
        aria-controls="pills-update-password"
        aria-selected="false"
      >
        Update Password
      </button>
    </li>
  </ul>
  <div class="tab-content col-xxl-6 col-xl-8 col-md-9" id="pills-tabContent">
    <div
      class="tab-pane fade show active"
      id="basic-info"
      role="tabpanel"
      aria-labelledby="basic-info-tab"
      tabindex="0"
    >
      <form class="container-fluid" @submit.prevent="updateProfile">
        <div class="mb-3 row">
          <label class="col-sm-3 col-form-label">First Name</label>
          <div class="col-sm-9">
            <input
              type="text"
              class="form-control"
              v-model="updatedData.first_name"
              data-cy=""
              :disabled="!activeRoleStore.activeRoles.includes('super_admin')"
            />
            <div v-if="profileErrors.first_name" class="text-danger mt-2 error">
              {{ profileErrors.first_name }}
            </div>
          </div>
        </div>
        <div class="mb-3 row">
          <label class="col-sm-3 col-form-label">Last Name</label>
          <div class="col-sm-9">
            <input
              type="text"
              class="form-control"
              v-model="updatedData.last_name"
              data-cy=""
              :disabled="!activeRoleStore.activeRoles.includes('super_admin')"
            />
            <div v-if="profileErrors.last_name" class="text-danger mt-2 error">
              {{ profileErrors.last_name }}
            </div>
          </div>
        </div>
        <div class="mb-3 row">
          <label class="col-sm-3 col-form-label">Email</label>
          <div class="col-sm-9">
            <input
              type="text"
              class="form-control"
              v-model="updatedData.email"
              disabled
              data-cy=""
            />
          </div>
        </div>
        <button
          class="btn btn-primary btn-sm ms-auto text-white d-block my-4"
          type="submit"
          v-if="activeRoleStore.activeRoles.includes('super_admin')"
        >
          Update
        </button>
      </form>
    </div>
    <div
      class="tab-pane fade"
      id="update-password"
      role="tabpanel"
      aria-labelledby="update-password-tab"
      tabindex="0"
    >
      <form class="container-fluid" @submit.prevent="updatePassword">
        <h5 class="ff-montserrat mb-4">Change your password</h5>
        <div class="notes">
          <ul class="fw-medium fst-italic">
            <li :class="{ 'text-success': passwordRequirements.hasLowerCase }">
              Password must contain at least one Small Letter
            </li>
            <li :class="{ 'text-success': passwordRequirements.hasUpperCase }">
              Password must contain at least one Capital Letter
            </li>
            <li :class="{ 'text-success': passwordRequirements.hasNumber }">
              Password must contain at least one Number
            </li>
            <li
              :class="{ 'text-success': passwordRequirements.hasSpecialChar }"
            >
              Password must contain at least one Special character (!@#$&*)
            </li>
            <li :class="{ 'text-success': passwordRequirements.hasMinLength }">
              Password must contain a minimum length of 8 characters
            </li>
          </ul>
        </div>
        <div class="mb-3 row">
          <label class="col-sm-5 col-form-label">Current Password</label>
          <div class="col-sm-7">
            <div class="position-relative">
              <input
                :type="showPassword ? 'text' : 'password'"
                class="form-control"
                v-model="oldPassword"
                data-cy=""
              />
              <div
                class="eye position-absolute"
                @click="showPassword = !showPassword"
              >
                <i class="bi bi-eye-fill" v-if="showPassword"></i>
                <i class="bi bi-eye-slash-fill" v-else></i>
              </div>
            </div>
            <div
              v-if="passwordErrors.oldPassword"
              class="text-danger mt-2 error"
            >
              {{ passwordErrors.oldPassword }}
            </div>
          </div>
        </div>
        <div class="mb-3 row">
          <label class="col-sm-5 col-form-label">New Password</label>
          <div class="col-sm-7">
            <div class="position-relative">
              <input
                :type="showConfirmPassword ? 'text' : 'password'"
                class="form-control"
                v-model="newPassword"
                data-cy=""
              />
              <div
                class="eye position-absolute"
                @click="showConfirmPassword = !showConfirmPassword"
              >
                <i class="bi bi-eye-fill" v-if="showConfirmPassword"></i>
                <i class="bi bi-eye-slash-fill" v-else></i>
              </div>
            </div>
            <div
              v-if="passwordErrors.newPassword"
              class="text-danger mt-2 error"
            >
              {{ passwordErrors.newPassword }}
            </div>
          </div>
        </div>
        <button
          class="btn btn-primary btn-sm ms-auto text-white d-block my-4"
          type="submit"
        >
          Update Password
        </button>
      </form>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.notes {
  li {
    color: var(--bs-danger);
    font-size: 14px;
    &.text-success {
      color: var(--bs-success);
    }
  }
}
.nav-link {
  border-radius: 0;
  border-bottom: 3px solid transparent;
  &.active {
    background-color: transparent;
    color: var(--bs-primary);
    border-color: var(--bs-primary);
  }
}

.eye {
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  cursor: pointer;
}
@media (max-width: 575px) {
  .nav-link {
    font-size: 14px;
  }
}
</style>
