<script setup lang="ts">
import { onBeforeMount, ref, watch } from "vue";
import AddUserModal from "./components/AddUserModal.vue";
import { appContainer } from "../../container";
import { UserService } from "../../services/userService";
import { LoadingService } from "../../services/loadingService";
import { InvitedUserInterface, Role } from "../../interface/UserInterface";
import { PagingInterface } from "../../interface/PaginationInterface";
import UserTypeListing from "./UserTypeListing.vue";
import PaginationComponent from "../../components/PaginationComponent.vue";
import { ActiveYearStore } from "../../stores/ActiveYearStore";

const paginatedData = ref<PagingInterface<InvitedUserInterface>>();
const invitedUsers = ref<InvitedUserInterface[]>([]);

const showAddModal = ref(false);
const invitationType = ref("");

const userService = appContainer.resolve(UserService);

const loadingService = appContainer.resolve(LoadingService);

const searchField = ref("");
const isLoading = ref(true);

const activeYearStore = ActiveYearStore();

function closeAddModal() {
  showAddModal.value = false;
}

onBeforeMount(async () => {
  const loader = await loadingService.show();
  await fetchUsers().then(() => {
    setTimeout(() => {
      loader.hide();
      isLoading.value = false;
    }, 500);
  });
});

watch(
  () => activeYearStore.activeYear,
  () => {
    fetchUsers();
  },
);
async function fetchUsers(
  pageConfig: { page?: number; offset?: number } = {
    page: 1,
    offset: 8,
  },
) {
  const loader = await loadingService.show();
  return userService
    .getInvitedUser(
      {
        invitationType: invitationType.value,
        name: searchField.value,
        year: activeYearStore.activeYear,
      },
      pageConfig,
    )
    .then((result) => {
      paginatedData.value = result;
      invitedUsers.value = result.items;
    })
    .finally(() => loader.hide());
}

function updatePage(pageNumber: number) {
  if (pageNumber === paginatedData.value?.page) {
    return;
  }
  fetchUsers({ page: pageNumber, offset: 8 });
}

async function addUser() {
  showAddModal.value = false;
  fetchUsers({ page: 1, offset: 8 });
  invitationType.value = "";
}

async function sendInvitation() {
  fetchUsers({ page: 1, offset: 8 });
}

async function editInvitation() {
  fetchUsers({ page: 1, offset: 8 });
}

async function deleteInvitation() {
  fetchUsers({ page: 1, offset: 8 });
}

watch(invitationType, () => {
  searchField.value = "";
  fetchUsers();
});

function searchInvitedUser() {
  if (searchField.value === "") {
    return;
  }
  fetchUsers();
}
watch(searchField, () => {
  if (searchField.value === "") {
    fetchUsers();
  }
});
</script>
<template>
  <template v-if="isLoading"></template>
  <template v-else>
    <div class="d-flex w-100 justify-content-between flex-wrap mb-4">
      <h2 class="fw-medium fs-4 p-0 mb-0">Manage Users</h2>
      <div class="btn-wrapper ms-auto d-flex align-items-center flex-wrap">
        <button
          class="btn btn-primary btn-sm text-white px-3"
          @click="showAddModal = true"
        >
          Add User
        </button>
      </div>
    </div>
    <form
      class="d-flex w-100 justify-content-between flex-wrap mb-4"
      @submit.prevent="searchInvitedUser"
    >
      <div class="search-wrapper d-flex m-0 position-relative w-100">
        <input
          type="search"
          class="form-control"
          placeholder="Search"
          v-model="searchField"
        /><button type="submit" class="text-white btn bg-primary flex-center">
          Search
        </button>
      </div>
    </form>
    <ul class="nav nav-pills my-4">
      <li
        class="nav-item p-2"
        :class="{ active: invitationType === '' }"
        @click="invitationType = ''"
      >
        <div class="d-flex align-items-center">
          <i class="bi bi-grid-fill"></i>
          All
        </div>
      </li>
      <li
        class="nav-item p-2"
        :class="{ active: invitationType === Role.Admin }"
        @click="invitationType = Role.Admin"
      >
        <div class="d-flex align-items-center">
          <i class="bi bi-gear-fill"></i>
          Admins
        </div>
      </li>
      <li
        class="nav-item p-2"
        :class="{ active: invitationType === Role.Participants }"
        @click="invitationType = Role.Participants"
      >
        <div class="d-flex align-items-center">
          <i class="bi bi-person-fill"></i>
          Participants
        </div>
      </li>
      <li
        class="nav-item p-2"
        :class="{ active: invitationType === Role.Raters }"
        @click="invitationType = Role.Raters"
      >
        <div class="d-flex align-items-center">
          <i class="bi bi-star-fill"></i>
          Raters
        </div>
      </li>
    </ul>
    <UserTypeListing
      v-if="invitedUsers.length"
      :invitedUsers="invitedUsers"
      @delete="deleteInvitation"
      @edit="editInvitation"
      @invite="sendInvitation"
    />
    <div
      class="no-result d-flex align-items-center justify-content-center text-opacity-50 text-danger"
      v-else
    >
      No Records Found
    </div>
    <div class="pagination-wrapper" v-if="paginatedData?.totalItems">
      <PaginationComponent
        v-if="paginatedData.totalItems > 8"
        :totalRows="paginatedData!.totalItems"
        :currentPage="paginatedData?.page"
        :perPage="paginatedData?.offset"
        @pageChange="updatePage"
      />
    </div>

    <AddUserModal v-if="showAddModal" @close="closeAddModal" @add="addUser" />
  </template>
</template>

<style lang="scss" scoped>
@import "../../theme/variables";

.nav-pills {
  gap: 10px;
}

.nav-item {
  border-radius: 0;
  border-bottom: 3px solid transparent;
  cursor: pointer;
  font-size: 14px;
  &.active {
    color: var(--bs-primary);
    border-color: var(--bs-primary);
  }
}
.all-users {
  min-height: calc(100% - 280px);
}
.search-wrapper {
  max-width: 350px;
  gap: 8px;
}
i {
  color: $blue;
  font-size: 18px;
  margin-right: 8px;
}
.bi-person-fill::before {
  font-size: 22px;
}
</style>
