<script setup lang="ts">
import { ref } from "vue";
import { appContainer } from "../../../container";
import { ErrorResponse } from "../../../interface/ErrorInterface";
import { InvitedUserInterface, Role } from "../../../interface/UserInterface";
import { LoadingService } from "../../../services/loadingService";
import { CompanyService } from "../../../services/manageCompanyService";
import { ToastService } from "../../../services/toastService";
import { UserService } from "../../../services/userService";
import { AxiosError } from "axios";
import ValidationErrorComponent from "../../../components/ValidationErrorComponent.vue";
// import { CurrentInvitationStore } from "../../../stores/CurrentInvtationStore";
// import { StorageService } from "../../../services/storage.service";
// import { ActiveRolesStore } from "../../../stores/ActiveRolesStore";
import { AuthService } from "../../../services/authService";

const props = defineProps<{
  invitation: InvitedUserInterface;
}>();
const emit = defineEmits(["close", "invite"]);
const userService = appContainer.resolve(UserService);
const companyService = appContainer.resolve(CompanyService);

const loadingService = appContainer.resolve(LoadingService);
const toastService = appContainer.resolve(ToastService);
const validationErrors = ref<ErrorResponse>();
// const currentInvitationStore = CurrentInvitationStore();
// const storageService = appContainer.resolve(StorageService);
// const activeRoleStore = ActiveRolesStore();
const authService = appContainer.resolve(AuthService);

async function handleInviteSend() {
  const loader = await loadingService.show();
  if (props.invitation.company_id) {
    companyService
      .sendInvitation(props.invitation.company_id, props.invitation.id)
      .then(() => {
        toastService.success("Invitation Sent Successfully");
        emit("invite");
      })
      .catch((err) => {
        const error = err as AxiosError;
        const errorResponse = error.response?.data as ErrorResponse;
        if (errorResponse?.errors) {
          const flattenedErrors = Object.values(errorResponse.errors).flat();
          toastService.error(flattenedErrors.join("<br>"));
        } else {
          toastService.error(`Unable to Send Invitation`);
        }
      })
      .finally(() => loader.hide());
  } else {
    userService
      .sendInvite(props.invitation.id)
      .then(() => {
        console.log("in then");
        authService.fetchUser();
        toastService.success("Invitation Sent Successfully");
        emit("invite");
      })
      .catch((err) => {
        console.log("in catch statement");
        const error = err as AxiosError;
        const errorResponse = error.response?.data as ErrorResponse;
        if (errorResponse?.errors) {
          const flattenedErrors = Object.values(errorResponse.errors).flat();
          toastService.error(flattenedErrors.join("<br>"));
        } else {
          // toastService.error(`Unable to Send Invitation jhsagdhasjdfhj`);
        }
      })
      .finally(() => loader.hide());
  }
}
</script>

<template>
  <div class="modal-backdrop">
    <div class="modal" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <ValidationErrorComponent
              v-if="validationErrors"
              :validations="validationErrors"
            ></ValidationErrorComponent>
            <p class="text-center mt-4 px-2 fs-5">
              Are you sure you want to send an invite to
              <strong class="text-capitalize">
                {{ invitation.invited_user.first_name }}
                {{ invitation.invited_user.last_name }}
              </strong>
              <span class="break-word ms-1">
                {{ invitation.invited_user.email }}</span
              >
              as
              {{ invitation.invitation_type === Role.Admin ? "an" : "a" }}
              <strong class="text-capitalize">
                {{ invitation.invitation_type }}</strong
              >
              <span
                v-if="
                  invitation.invitation_type === Role.Participants ||
                  invitation.invitation_type === Role.Raters
                "
              >
                for
                {{ invitation.invited_for_user?.first_name }}
                {{ invitation.invited_for_user?.last_name }} </span
              >?
            </p>

            <small class="text-danger text-center d-block">
              **Note: After sending the invite, you cannot edit or delete user**
            </small>

            <div
              class="d-flex align-items-center justify-content-evenly p-4 gap-3 flex-wrap"
            >
              <button
                type="button"
                class="btn btn-danger btn-sm text-white"
                @click="emit('close')"
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-secondary btn-sm text-white"
                @click="handleInviteSend"
              >
                Send Invitation
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
button {
  width: calc(50% - 1.5rem);
}
@media (max-width: 767px) {
  button {
    width: 100%;
  }
}
</style>
