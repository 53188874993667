<script setup lang="ts">
import { ref } from "vue";
import { ErrorResponse } from "../../../interface/ErrorInterface";
import { InvitedUserInterface } from "../../../interface/UserInterface";

import { appContainer } from "../../../container";
import { LoadingService } from "../../../services/loadingService";
import { ToastService } from "../../../services/toastService";
import { AxiosError } from "axios";
import ValidationErrorComponent from "../../../components/ValidationErrorComponent.vue";
import { UserService } from "../../../services/userService";

const props = defineProps<{
  invitation: InvitedUserInterface;
}>();

const emit = defineEmits(["done", "close"]);

const userService = appContainer.resolve(UserService);
const loadingService = appContainer.resolve(LoadingService);
const toastService = appContainer.resolve(ToastService);
const validationErrors = ref<ErrorResponse>();

async function handleFeedbackCompletion() {
  const loader = await loadingService.show();
  if (props.invitation.company_id) {
    userService
      .feedbackCompletion(props.invitation.id)
      .then(() => {
        toastService.success("Feedback Completed Successfully");
        emit("done");
      })
      .catch((err) => {
        const error = err as AxiosError;
        const errorResponse = error.response?.data as ErrorResponse;
        if (errorResponse?.errors) {
          const flattenedErrors = Object.values(errorResponse.errors).flat();
          toastService.error(flattenedErrors.join("<br>"));
        } else {
          toastService.error(`Unable to Completed Feedback`);
        }
      })
      .finally(() => loader.hide());
  }
}
</script>

<template>
  <div class="modal-backdrop">
    <div class="modal" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <ValidationErrorComponent
              v-if="validationErrors"
              :validations="validationErrors"
            ></ValidationErrorComponent>
            <p class="text-center mt-4 px-2 fs-5">
              Are you sure you want to complete the feedback of
              <strong class="text-capitalize">
                {{ invitation.invited_user.first_name }}
                {{ invitation.invited_user.last_name }}
              </strong>
              <span class="break-word ms-1">
                {{ invitation.invited_user.email }}</span
              >
            </p>

            <small class="text-danger text-center d-block">
              **Note: Once you complete the feedback, you cannot add more raters
              or make any changes.**
            </small>

            <div
              class="d-flex align-items-center justify-content-evenly p-4 gap-3 flex-wrap"
            >
              <button
                type="button"
                class="btn btn-danger btn-sm text-white"
                @click="emit('close')"
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-secondary btn-sm text-white"
                @click="handleFeedbackCompletion"
              >
                Complete Feedback
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
button {
  width: calc(50% - 1.5rem);
}
@media (max-width: 767px) {
  button {
    width: 100%;
  }
}
</style>
